import { FC, ReactElement, useMemo, useState } from 'react';
import { Box, Flex, FlexProps, Stack, Text } from '@chakra-ui/react';
import { App, Collection } from '@workgood/types';
import AllShortcutsLink from './AllShortcutsLink';
import useCollections from './useCollections';
import CollectionList from './CollectionList';
import StarCollection from './StarCollection';
import CollectionSearch from './CollectionSearch';
import { LITERALS } from 'utils/constants';

interface CollectionProps {
  app: App;
  collections: Collection[];
  loading?: boolean;
  selected?: Collection;
}

export const Collections: FC<FlexProps & CollectionProps> = ({
  app,
  collections
}) => {
  const { likedCollections, myCollections } = useCollections();
  const [search, setSearch] = useState({ result: [], showEmpty: false });

  const likedCollectionsIndex = useMemo(
    () =>
      likedCollections.reduce(
        (acc, collection) => ({ ...acc, [collection.id]: true }),
        {}
      ),
    [likedCollections]
  );
  const collectionDedupeIndex = useMemo(
    () =>
      likedCollections
        .concat(myCollections)
        .reduce((acc, collection) => ({ ...acc, [collection.id]: true }), {}),
    [likedCollections]
  );

  let searchResults: ReactElement;
  if (search.showEmpty) {
    searchResults = (
      <Flex alignItems={'center'} pl={[6, 10]} pt={2}>
        <Text
          mb={1}
          mt={0}
          textAlign="center"
          fontSize={'sm'}
          display={{ base: 'block', md: 'block' }}
          color="gray.200"
          fontWeight={'semibold'}
        >
          No matching collections found.
        </Text>
      </Flex>
    );
  } else if (search?.result?.length) {
    searchResults = (
      <CollectionList
        collections={search.result}
        actionIcon={(collection) => (
          <StarCollection
            isLiked={likedCollectionsIndex[collection.id]}
            collection={collection}
          />
        )}
        caption={(collection) => (
          <Text fontSize="xs" color="gray.400">
            {collection?.Collection_Features?.length} shortcuts
          </Text>
        )}
      />
    );
  }

  return (
    <Box
      h="100%"
      position={['static', 'fixed']}
      className="windows-hidden-scrollbar"
      style={{
        WebkitOverflowScrolling: 'touch',
        overscrollBehavior: 'contain',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        width: '350px'
      }}
    >
      <Flex
        direction="column"
        w="100%"
        display={['flex', 'flex']}
        h="100%"
        className="windows-hidden-scrollbar"
        style={{
          WebkitOverflowScrolling: 'touch',
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          overscrollBehavior: 'contain'
        }}
      >
        <Flex
          position={['relative', 'absolute']}
          top={0}
          left={0}
          width={['100%', '350px']}
          alignItems={'center'}
          flex={'0 0 49px'}
          h={49}
          borderBottom={'1px'}
          borderColor="brand.400"
          px={[2, 4]}
          mb={4}
        >
          <CollectionSearch
            onSearch={(result, showEmpty) => setSearch({ result, showEmpty })}
          />
        </Flex>
        <Stack
          height="101%"
          overflowY="scroll"
          className="windows-hidden-scrollbar"
          style={{
            overscrollBehavior: 'contain',
            msOverflowStyle: 'none',
            scrollbarWidth: 'none'
          }}
          mt={[1, '50px']}
        >
          {searchResults ? (
            <>
              <Flex alignItems={'center'} pl={[6, 10]} pt={2}>
                <Text
                  mb={1}
                  mt={0}
                  fontSize={'xs'}
                  display={{ base: 'block', md: 'block' }}
                  color="gray.400"
                  fontWeight={'semibold'}
                >
                  SEARCH RESULTS
                </Text>
              </Flex>
              {searchResults}
            </>
          ) : (
            <>
              {app && <AllShortcutsLink app={app} />}
              {!!myCollections?.length && (
                <>
                  <Flex alignItems={'center'} pl={[6, 10]} pt={2}>
                    <Text
                      mb={1}
                      mt={6}
                      fontSize={'xs'}
                      display={{ base: 'block', md: 'block' }}
                      color="gray.400"
                      fontWeight={'semibold'}
                      textTransform="uppercase"
                    >
                      MY {LITERALS.COLLECTIONS}
                    </Text>
                  </Flex>
                  <CollectionList
                    collections={myCollections}
                    // actionIcon={(collection) => (
                    //   <CollectionMenu collection={collection} />
                    // )}
                    caption={(collection) => (
                      <Text fontSize="xs" color="gray.400">
                        {
                          collection?.Collection_Features_aggregate?.aggregate
                            ?.count
                        }{' '}
                        shortcuts
                      </Text>
                    )}
                  />
                </>
              )}
              <Flex alignItems={'center'} pl={[6, 10]} pt={2}>
                <Text
                  mt={6}
                  mb={1}
                  fontSize={'xs'}
                  display={{ base: 'block', md: 'block' }}
                  color="gray.400"
                  fontWeight={'semibold'}
                  textTransform="uppercase"
                >
                  {LITERALS.COLLECTIONS}
                </Text>
              </Flex>
              <CollectionList
                collections={likedCollections}
                actionIcon={(collection) => (
                  <StarCollection isLiked={true} collection={collection} />
                )}
                caption={(collection) => (
                  <Text fontSize="sm" fontWeight={400} color="gray.400">
                    {
                      collection?.Collection_Features_aggregate?.aggregate
                        ?.count
                    }{' '}
                    shortcuts
                  </Text>
                )}
              />
              <CollectionList
                collections={collections.filter(
                  (c) => !collectionDedupeIndex[c.id]
                )}
                actionIcon={(collection) => (
                  <StarCollection isLiked={false} collection={collection} />
                )}
                caption={(collection) => (
                  <Text fontSize="sm" fontWeight={400} color="gray.400">
                    {
                      collection?.Collection_Features_aggregate?.aggregate
                        ?.count
                    }{' '}
                    shortcuts
                  </Text>
                )}
              />
            </>
          )}
        </Stack>
      </Flex>
    </Box>
  );
};

export default Collections;
