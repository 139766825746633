import { FC, ReactElement, useContext, useState } from 'react';
import { AppContext } from 'utils/contexts/AppContext';
import router, { useRouter } from 'next/router';
import { Flex, FlexProps, Link, Text } from '@chakra-ui/react';
import { Collection } from '@workgood/types';
import { buildUrl, parseUrl } from 'utils/route';
import { ListItem } from 'components/molecules';
import CollectionSkeleton from './CollectionsSkeleton';

interface CollectionListProps {
  collections: Collection[];
  loading?: boolean;
  actionIcon?: (collection: Collection) => ReactElement;
  actionText?: string;
  caption?: (collection: Collection) => ReactElement;
  onSelect?: (collectionId: string) => void;
}

export const CollectionList: FC<FlexProps & CollectionListProps> = ({
  collections,
  loading,
  caption = () => <></>,
  onSelect = () => null,
  actionIcon = () => null,
  actionText
}) => {
  const {
    query: { slug },
    pathname
  } = useRouter();
  const { platform, collectionId } = parseUrl(slug, pathname);
  const { onDrawerClose, app } = useContext(AppContext);

  if (!collections?.length || !app) return <></>;

  return (
    <Flex flexDirection={'column'} mt={'0 !important'}>
      {loading ? (
        <CollectionSkeleton />
      ) : !collections || !app ? (
        <div>error</div>
      ) : (
        collections
          .map((c) => ({
            collection: c,
            active: collectionId == c.id,
            url: buildUrl({
              platform,
              appSlug: app.slug,
              collectionId: c.id,
              collectionName: c.name
            })
          }))
          .map(({ collection, url, active }) => (
            <Link
              _focus={{ outline: '0', boxShadow: 'none' }}
              style={{ textDecoration: 'none !important' }}
              key={collection.id}
              onClick={(e) => {
                e.preventDefault();
                onSelect(collection.id);
                analytics.track('Collection Item Clicked', {
                  app: app.name,
                  collection: collection.name
                });
                setTimeout(
                  () => router.push(url, undefined, { shallow: true }),
                  0
                );

                onDrawerClose();
              }}
              href={url}
              marginTop={'0 !important'}
            >
              <ListItem
                px={[0, 3]}
                py={3}
                my={0}
                key={collection.id}
                actionIcon={actionIcon(collection)}
                actionText={actionText}
                active={active}
                layerStyle={active ? 'listItemActive' : 'listItemBase'}
                borderLeft={'none'}
                text={
                  <Text size="md" style={{ textDecoration: 'none !important' }}>
                    {collection.name}
                  </Text>
                }
                caption={caption(collection)}
                cursor="pointer"
              />
            </Link>
          ))
      )}
    </Flex>
  );
};

export default CollectionList;
