import Image from 'next/image';
import { Stack, Skeleton, Center, Divider } from '@chakra-ui/react';

const AppsSkeleton = () => (
  <Stack width="72px" px={3} display={['none', 'flex']}>
    <Center p={1} borderRadius={10}>
      <Image
        height={40}
        width={40}
        src="/brand/icon-transparent-ondark.png"
        alt={`WorkGood`}
      />
    </Center>

    <Divider borderColor="brand.500" mb={4} />

    <Skeleton height={'32px'} mb={4} />
    <Skeleton height={'32px'} mb={4} />
    <Skeleton height={'32px'} mb={4} />
  </Stack>
);

export default AppsSkeleton;
