import { FC } from 'react';
import { Box, Button, Center, Flex, Text, Tooltip } from '@chakra-ui/react';
import { Feature, Feature_Preview } from '@workgood/types';
import get from 'lodash/get';
import Image from 'next/image';
import {
  FaExclamationCircle,
  FaFlag,
  FaFolderPlus,
  FaUpload
} from 'react-icons/fa';
import { PreviewMode } from '../FeaturePreview';
import { RiThumbUpFill } from 'react-icons/ri';
import LikeButton from '../LikeButton';
import Access from 'modules/shared/acl/Access';

interface ShortcutsProps {
  feature?: Feature;
  onPreviewModeChange: (mode: PreviewMode) => void;
}

export const Preview: FC<ShortcutsProps> = ({
  feature,
  onPreviewModeChange
}) => {
  const preview = get(feature, 'Previews.0') as Feature_Preview;
  const previewWidth = 294;
  const { height, width } = preview ?? { height: 180, width: previewWidth };
  const previewHeight = ~~((height * previewWidth) / width);

  const actions = (
    <Flex mx={1} justify="space-between">
      <Flex>
        <Box mr={2}>
          <LikeButton feature={feature}></LikeButton>
        </Box>
        <Access
          context={{}}
          resource="collectionFeature"
          action="add"
          onClick={() => onPreviewModeChange(PreviewMode.Collection)}
        >
          <Button
            aria-label="Save"
            color="gray.500"
            _hover={{
              color: 'gray.600'
            }}
            size={'sm'}
            variant={'link'}
            colorScheme={'blue'}
            mr={2}
            leftIcon={<FaFolderPlus size={20} />}
          >
            Save
          </Button>
        </Access>
      </Flex>
      <Flex>
        {/* <Tooltip
          label={
            <Box>
              <Text>Report Preview</Text>
            </Box>
          }
          fontSize="sm"
        >
          <Button
            aria-label="Expand"
            color="gray.500"
            _hover={{
              color: 'gray.600'
            }}
            size={'sm'}
            variant={'link'}
            colorScheme={'blue'}
            leftIcon={
              <FaFlag
                size={16}
                onClick={() => onPreviewModeChange(PreviewMode.Report)}
              />
            }
            ml={1}
          >
            Report
          </Button>
        </Tooltip> */}
      </Flex>
    </Flex>
  );

  return (
    <Flex direction="column">
      <Center
        role="group"
        width={previewWidth}
        height={previewHeight}
        mb={2}
        bg="white"
        position={'relative'}
        border="1px solid"
        borderColor="gray.300"
        borderRadius="6px"
        overflow={'hidden'}
      >
        {preview?.file_name ? (
          <>
            <Image src={`features/${preview.file_name}`} layout="fill" />
          </>
        ) : (
          <Box py={8}>
            <Box color="gray.400" mt={2} textAlign="center">
              <Button
                aria-label="Submit a Preview"
                color="gray.500"
                _hover={{
                  color: 'blue.600'
                }}
                size={'sm'}
                variant={'link'}
                colorScheme={'blue'}
                onClick={() => onPreviewModeChange(PreviewMode.Upload)}
                leftIcon={<FaUpload size={16} />}
              >
                <Text fontSize={'sm'}>Submit a Preview</Text>
              </Button>
              {/* <Text fontSize={'xs'} color="gray.500">
                Earn 2 credits
              </Text> */}
            </Box>
          </Box>
        )}
      </Center>
      {actions}
    </Flex>
  );
};

export default Preview;
