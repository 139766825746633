import { FC, memo, useContext, useState } from 'react';
import { AppContext } from 'utils/contexts/AppContext';
import router, { useRouter } from 'next/router';
import { Link, Text, Spinner, Flex } from '@chakra-ui/react';
import { App } from '@workgood/types';
import { buildUrl, parseUrl } from 'utils/route';
import { ListItem } from 'components/molecules';

interface AllShortcutsLinkProps {
  app: App;
  loading?: boolean;
  onClick?: () => void;
}

export const AllShortcutsLink: FC<AllShortcutsLinkProps> = memo(({
  app,
  loading,
  onClick = () => null
}) => {
  const {
    query: { slug },
    pathname
  } = useRouter();
  const { platform, collectionId } = parseUrl(slug, pathname);
  const { onDrawerClose, collection } = useContext(AppContext);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const active = !collectionId;

  return (
    <Link
      key={buildUrl({
        platform,
        appSlug: app.slug
      })}
      borderRadius={1}
      onClick={(e) => {
        e.preventDefault();
        setSelectedCollection(null);
        analytics.track('All Shortcuts Clicked', {
          app: app.name
        });
        onClick();
        setTimeout(
          () =>
            router.push(
              buildUrl({
                platform,
                appSlug: app.slug,
                collectionName: 'all'
              }),
              undefined,
              { shallow: true }
            ),
          0
        );

        onDrawerClose();
      }}
      href={buildUrl({
        platform,
        appSlug: app.slug
      })}
      mt={0}
    >
      <ListItem
        px={[0, 3]}
        py={3}
        mt={4}
        borderRadius={1}
        key={'all'}
        layerStyle={active ? 'listItemActive' : 'listItemBase'}
        borderLeft={'none'}
        _focus={{ outline: 'none', boxShadow: 'none' }}
        text={
          <Flex alignItems={'center'}>
            <Text
              fontSize={'md'}
              display={{ base: 'block', md: 'block' }}
              color="yellow.50"
              fontWeight={active ? 'semibold': '500'}
            >
              All Shortcuts
            </Text>
          </Flex>
        }
        cursor="pointer"
        statusIcon={
          loading && (
            <Spinner
              thickness="2px"
              speed="0.65s"
              emptyColor="blue.400"
              color="blue.200"
              size="sm"
            />
          )
        }
      />
    </Link>
  );
});

export default AllShortcutsLink;
