import React, { useState, createContext, FC } from 'react';
import { AccessControl } from 'role-acl';
import { Practice } from 'modules/shared/types';
import { User } from '@workgood/types';
import { GRANTS } from 'modules/shared/acl/grants';

type UserContextProps = {
  user?: User;
  practice: Practice;
  accessControl: AccessControl;
  setUser: (user: User) => void;
  setPractice: (practice: Practice) => void;
};

type UserState = {
  user?: User;
  practice?: Practice;
};

export const defaultUserContext = {
  user: null,
  practice: null
};

export const UserContext = createContext<Partial<UserContextProps>>(
  defaultUserContext
);
export const UserContextProvider: FC = ({ children }) => {
  const [state, setState] = useState<UserState>(defaultUserContext);
  const [accessControl] = useState<AccessControl>(new AccessControl(GRANTS));

  const can = ({
    resource,
    action,
    context
  }: {
    resource: string;
    action: string;
    context: any;
  }) => {
    const role = !!state?.user ? 'user' : 'anonymous';
    return accessControl
      .can(role)
      .context(context)
      .execute(action)
      .on(resource);
  };

  const value = {
    ...state,
    accessControl,
    can,
    setUser: (user: User) => {
      setState({ ...state, user });
    },
    setPractice: (practice: Practice) => {
      setState({ ...state, practice });
    }
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
