import React, { FC, useState } from 'react';
import groupBy from 'lodash/groupBy';
import { useRouter } from 'next/router';
import { AppLogo, Binding } from 'components/atoms';
import { Heading, Flex, FlexProps, Box, Text } from '@chakra-ui/react';
import { App, Collection, Feature } from '@workgood/types';
import { parseUrl } from 'utils/route';
import { AppContent, ContentHeader } from 'modules/shared/templates';
import FeaturePreview from 'modules/shortcuts/components/FeaturePreview';
import FeatureStat from 'modules/shortcuts/components/FeatureStat';
import FeatureModal from 'modules/shortcuts/components/FeatureModal';
import { getPlatform } from 'utils/device';

interface CollectionProps {
  app: App;
  collections: Collection[];
  loading?: boolean;
  selected?: Collection;
}

export const AllShortcuts: FC<FlexProps & CollectionProps> = ({ app }) => {
  const {
    query: { slug },
    pathname
  } = useRouter();
  const [featureFocus, setFeatureFocus] = useState<Feature>(null);
  const [isFeatureModalOpen, setIsFeatureModalOpen] = useState<boolean>(null);
  const { platform } = parseUrl(slug, pathname);

  if (!app) return <></>;

  const categories = groupBy(app?.Features, 'category');

  return (
    <AppContent
      header={
        <ContentHeader
          title={`All Shortcuts`}
          subTitle={`for ${app.name}`}
          image={
            <AppLogo
              dimensions={[40, 40]}
              params={['c_pad']}
              src={`/logos/${app.slug}.png`}
              alt={`${app.name} logo`}
            />
          }
        />
      }
    >
      <Flex
        direction="column"
        align="center"
        w="100%"
        minH={600}
        h={'100%'}
        mx="auto"
        pb={20}
        overflowY={'auto'}
      >
        {Object.keys(categories).map((category) => (
          <>
            <Heading
              key={`heading-${category}`}
              size="md"
              w={'100%'}
              maxW={600}
              textAlign="left"
              mt={8}
              mb={2}
              px={4}
              as="h4"
              fontWeight={'semibold'}
            >
              {category}
            </Heading>
            {categories[category]
              .filter((feature) => !!feature[`${getPlatform()}_binding`])
              .map((feature, index) => (
                <Flex
                  maxW={600}
                  w={'100%'}
                  key={`shortcuts-${category}-${feature?.id}`}
                >
                  <Box
                    width="100%"
                    mb={{ base: 1, md: 1, lg: 2 }}
                    px={4}
                  >
                    <FeaturePreview
                      feature={feature}
                      onExpandClick={() => {
                        setFeatureFocus(feature);
                        setIsFeatureModalOpen(true);
                      }}
                    >
                      <Flex
                        key={feature.name}
                        justify="space-between"
                        alignItems={'center'}
                        mt={{ base: 1, md: 1, lg: 2 }}
                        mb={{ base: 1 }}
                        py={2}
                        px={4}
                        borderRadius={'md'}
                        width="100%"
                        bg={'white'}
                        shadow="sm"
                        cursor={'pointer'}
                        boxSizing="border-box"
                        border="1px solid transparent"
                        _hover={{
                          shadow: 'md',
                          border: '1px solid',
                          borderColor: 'gray.300'
                        }}
                      >
                        <Text
                          fontSize={{ base: 'md', md: 'md' }}
                          pr={2}
                          fontWeight="500"
                          textAlign={'left'}
                        >
                          {feature.name}
                        </Text>
                        <Binding feature={feature} platform={platform} />
                      </Flex>
                    </FeaturePreview>
                    {/* <Flex mx={4} justifyContent="space-between">
                      <FeatureStat feature={feature} />
                    </Flex> */}
                  </Box>
                  <FeatureModal
                    feature={featureFocus}
                    isOpen={isFeatureModalOpen}
                    onClose={() => setIsFeatureModalOpen(false)}
                  />
                </Flex>
              ))}
          </>
        ))}
      </Flex>
    </AppContent>
  );
};

export default AllShortcuts;
