import { useContext } from 'react';
import dynamic from 'next/dynamic';
import { ModalLogin } from 'modules/auth/components/ModalLogin';
// const ModalLogin = dynamic(() => import('modules/auth/components/ModalLogin'));
const ModalPayment = dynamic(
  () => import('modules/auth/components/ModalPayment')
);
import router from 'next/router';
import { AppContext } from 'utils/contexts';

interface AccessProps {
  redirectUrl?: string;
}

export const AccessModals = ({ redirectUrl = null }: AccessProps) => {
  const { isSignImModalOpen, isPaymentModalOpen, onModalClose } = useContext(
    AppContext
  );
  const handleLoginSuccess = () => {
    analytics.track('Login Success');
    onModalClose();
    if (redirectUrl) {
      router.push(redirectUrl, undefined, {
        shallow: true
      });
    } else {
      router.reload();
    }
  };
  return (
    <>
      {isSignImModalOpen && (
        <ModalLogin
          redirectUrl={redirectUrl}
          title="Sign up to continue"
          open={isSignImModalOpen}
          onSuccess={handleLoginSuccess}
          onClose={onModalClose}
        />
      )}
      {isPaymentModalOpen && (
        <ModalPayment
          redirectUrl={redirectUrl}
          title=""
          open={isPaymentModalOpen}
          onSuccess={handleLoginSuccess}
          onClose={onModalClose}
        />
      )}
    </>
  );
};

export default AccessModals;
