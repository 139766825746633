import React from 'react';
import { Flex, Button, useColorMode, Text, useColorModeValue } from '@chakra-ui/react';
import NextLink from 'next/link';

export const Footer = () => {
  const date = new Date().getFullYear();

  function FooterLink(props) {
    const { href, name, ...rest } = props;

    return (
      <NextLink href={href} passHref>
        <Button
          fontSize={['sm', 'md']}
          variant="unstyled"
          {...rest}
          color={useColorModeValue('gray.700', 'gray.700')}
          _hover={{ color: useColorModeValue('gray.700', 'gray.700') }}
        >
          {name}
        </Button>
      </NextLink>
    );
  }

  return (
    <Flex justify="space-between" w="100%" display={{ md: 'flex' }} py={4}>
      <Text
        fontSize="sm"
        color={useColorModeValue('gray.700', 'gray.700')}
        align={['center', 'left']}
      >
        © {date} WorkGood{' '}
      </Text>
      <Flex justify={{sm: 'space-around', md: 'flex-end'}}>
        <FooterLink href="/terms-of-service" name="Terms of Service" mr={{sm: 0, md: 8}} />
        <FooterLink href="/privacy-policy" name="Privacy Policy" mr={{sm: 0, md: 8}} />
        <FooterLink href="/cookie-policy" name="Cookie Policy" />
      </Flex>
    </Flex>
  );
};
export default Footer;
