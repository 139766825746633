import { Flex, Stack, Skeleton } from '@chakra-ui/react';

const CollectionsSkeleton = () => (
  <Flex mt={4} minH={'100vh'} w="100%" display={['none', 'flex']}>
    <Stack
      my={{ base: 1, md: 2 }}
      borderRadius={'md'}
      shadow="sm"
      py={4}
      px={6}
    >
      <Skeleton h={4} width={40} mb={8} />
      <Skeleton h={4} width={24} mb={4} />
      {Array.from(Array(6)).map((num, i) => (
        <Skeleton key={`${num}-${i}`} my={4} py={2} width={40} />
      ))}
    </Stack>
  </Flex>
);

export default CollectionsSkeleton;
