import React, { FC } from 'react';
import { App } from '@workgood/types';
import { Box, FlexProps, Text, Flex, Link, Button } from '@chakra-ui/react';
import AppList from './AppList';
import { APP_CATEGORIES, LITERALS } from 'utils/constants';
import useApps from './useApps';

interface AppProps {
  apps: App[];
  loading?: boolean;
  selected: string;
  onAppSelect?: (app: App) => void;
  onSelectionModeChange?: (mode: boolean) => void;
}

export const Apps: FC<FlexProps & AppProps> = ({ apps }) => {
  const { myApps, myAppsIndex } = useApps();

  return (
    <Box
      position={['static', 'fixed']}
      w={{ sm: '70px', md: '70px', lg: '250px' }}
      bg="brand.800"
      zIndex={2}
      h={'calc(100% - 50px)'}
      overflow="scroll"
      className="windows-hidden-scrollbar"
      style={{
        WebkitOverflowScrolling: 'touch',
        msOverflowStyle: 'none',
        overscrollBehavior: 'contain'
      }}
    >
      <Flex
        pb={8}
        flexDirection="column"
        overflowY="auto"
        className="windows-hidden-scrollbar"
        style={{
          overscrollBehavior: 'contain',
          msOverflowStyle: 'none',
          scrollbarWidth: 'none'
        }}
      >
        {!!myApps?.length && (
          <Box mb={10} mt={8}>
            <AppList
              apps={myApps ?? []}
              isLiked={true}
              title={LITERALS.LIKED_APPS}
              initialLimit={0}
            />
          </Box>
        )}

        {APP_CATEGORIES.map(({ title, filter }) => (
          <Box mt={8}>
            <AppList
              title={title}
              apps={filter(apps).filter(({ id }) => !myAppsIndex[id])}
              initialLimit={3}
            />
          </Box>
        ))}

        <Box
          display="none"
          position="absolute"
          bottom="0"
          padding={3}
          paddingLeft={4}
          width="100%"
        >
          <Link
            size="sm"
            color="gray.300"
            href="https://workgood.canny.io/"
            target="_blank"
          >
            Submit Feedback
          </Link>
        </Box>
      </Flex>
    </Box>
  );
};

export default Apps;
export { default as AppsSkeleton } from './AppsSkeleton';
