import React, { FC, memo } from 'react';
import AppBarContainer from './components/AppBarContainer';
import dynamic from 'next/dynamic';
const AppBarButtons = dynamic(() => import('./components/AppBarButtons'), {
  ssr: false
});

const AppBar: FC = memo(() => {
  return (
    <AppBarContainer>
      <AppBarButtons />
    </AppBarContainer>
  );
});

export default AppBar;
