import { FC, ReactElement } from 'react';
import {
  Button,
  Stack,
  Flex,
  Center,
  FlexProps,
  IconButton
} from '@chakra-ui/react';
import { FaRegFolder, FaRegFolderOpen } from 'react-icons/fa';

interface ListItemProps {
  logo?: string;
  background?: string;
  text: ReactElement | string;
  caption?: ReactElement | string;
  actionText?: ReactElement | string;
  actionIcon?: ReactElement;
  statusIcon?: ReactElement;
  image?: ReactElement;
  leftIcon?: ReactElement;
  imagePadding?: number;
  active?: boolean;
  onClick?: () => void;
}

export const ListItem: FC<FlexProps & ListItemProps> = ({
  text,
  background,
  caption,
  image,
  imagePadding = 1.75,
  leftIcon,
  active,
  actionText,
  actionIcon,
  statusIcon,
  onClick = () => undefined,
  ...props
}) => {
  return (
    <Flex
      role="group"
      onClick={onClick}
      {...props}
      w="100%"
      justify="space-between"
      align="center"
    >
      {(image && (
        <Center
          // active={active}
          // align="center"
          p={imagePadding}
          borderRadius={4}
          mr={3}
        >
          {image}
        </Center>
      )) || <></>}
      <Flex alignItems={'center'}>
        <Flex w={[6, 30]} pl={[0, 2]}>
          {leftIcon}
        </Flex>
        <Stack className="listItemText" spacing={0} justify="center" flex={1}>
          {text}
          {caption}
        </Stack>
      </Flex>
      {actionText && actionIcon && (
        <Button
          rightIcon={actionIcon}
          colorScheme="teal"
          variant="link"
          style={{ paddingLeft: '10px' }}
        >
          {actionText}
        </Button>
      )}
      {!actionText && actionIcon}
      {statusIcon ?? ''}
    </Flex>
  );
};

export default ListItem;
