import { FC, useCallback, useState } from 'react';
import Image from 'next/image'
import {
  Box,
  Button,
  Center,
  Flex,
  IconButton,
  List,
  ListIcon,
  ListItem,
  Text,
  Tooltip
} from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';

import { Feature } from '@workgood/types';
import {
  ApolloClient,
  NormalizedCacheObject,
  useApolloClient
} from '@apollo/client';
import { FaInfoCircle, FaUpload } from 'react-icons/fa';
import { BackButton } from '../shared/BackButton';
import { PreviewMode } from '../FeaturePreview';
import { BsFileEarmarkPlay } from 'react-icons/bs';
import { BiCheckCircle } from 'react-icons/bi';
import { UserService } from 'services';
import { useUser } from 'modules/auth';

interface UploadProps {
  feature?: Feature;
  onPreviewModeChange: (mode: PreviewMode) => void;
}

export const Upload: FC<UploadProps> = ({ feature, onPreviewModeChange }) => {
  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>;
  const { user } = useUser();
  const [files, setFiles] = useState([]);
  const [base64Files, setBase4Files] = useState([]);
  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);

    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        const base64Image = reader.result;
        setBase4Files([base64Image]);
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const onClear = () => {
    setFiles([]);
    setBase4Files([]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1
  });

  const onUploadClick = ({ feature_id, user_id }) => {
    UserService.uploadFeaturePreview(
      client,
      feature_id,
      user_id,
      base64Files[0]
    );
  };

  const actions =
    base64Files.length > 0 ? (
      <Flex justifyContent={'space-between'}>
        <BackButton onClick={onPreviewModeChange} />
        <Flex>
          <Button
            aria-label="Like Feature"
            color="gray.500"
            _hover={{
              color: 'gray.600'
            }}
            mr={3}
            size={'sm'}
            variant={'outline'}
            colorScheme={'gray'}
            onClick={onClear}
          >
            Clear
          </Button>
          <Button
            aria-label="Like Feature"
            color="white"
            _hover={{
              color: 'gray.600'
            }}
            size={'sm'}
            variant={'solid'}
            colorScheme={'teal'}
            onClick={() =>
              onUploadClick({ feature_id: feature.id, user_id: user?.id })
            }
            leftIcon={<FaUpload size={20} />}
          >
            Upload
          </Button>
        </Flex>
      </Flex>
    ) : (
      <Flex justifyContent={'space-between'}>
        <BackButton onClick={onPreviewModeChange} />
        <Flex>
          <Tooltip
            label={
              <Box p={3}>
                <Text fontWeight={'bold'} mb={2}>
                  Screenshot Video:
                </Text>
                <List spacing={3}>
                  <ListItem>
                    <Flex alignItems={'center'}>
                      <ListIcon as={BiCheckCircle} color="green.500" />
                      <Text color="gray.200">
                        Shows the effect of using the feature
                      </Text>
                    </Flex>
                  </ListItem>
                  <ListItem>
                    <Flex alignItems={'center'}>
                      <ListIcon as={BiCheckCircle} color="green.500" />
                      <Text color="gray.200">No foul or offensive content</Text>
                    </Flex>
                  </ListItem>
                  <ListItem>
                    <Flex alignItems={'center'}>
                      <ListIcon as={BiCheckCircle} color="green.500" />
                      <Text color="gray.200">Max file size: 20MB</Text>
                    </Flex>
                  </ListItem>
                </List>
                <Flex mb={2} mt={4}>
                  <Text fontWeight={'bold'} mr={2}>
                    Example:
                  </Text>
                  <Text fontStyle={'italic'}>Google Sheets - Bold Font</Text>
                </Flex>
                <Image
                  src={
                    'https://res.cloudinary.com/workgood/f_auto,c_limit,w_3840,q_auto/features/google-sheets__bold-font.gif'
                  }
                  layout="fill"
                />
              </Box>
            }
            fontSize="sm"
          >
            <Flex alignItems={'center'}>
              <Text color="gray.500" mr={2}>
                <FaInfoCircle />
              </Text>
              <Text color="gray.500" fontWeight={'semibold'} fontSize="sm">
                What should I upload?
              </Text>
            </Flex>
          </Tooltip>
        </Flex>
      </Flex>
    );

  return (
    <Flex direction={'column'}>
      <Center
        role="group"
        width={294}
        height={180}
        mb={2}
        bg="white"
        position={'relative'}
        border={isDragActive ? '3px dashed' : '1px solid'}
        borderColor={isDragActive ? 'blue.400' : 'gray.300'}
        borderRadius="6px"
        overflow={'hidden'}
      >
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <Box py={6}>
            {base64Files.length > 0 ? (
              <Image src={base64Files[0]} layout="fill" />
            ) : (
              <Box color="gray.600" mt={2} textAlign='center'>
                <Box mb={4}>
                  <IconButton
                    variant="link"
                    aria-label="upload preview"
                    icon={<BsFileEarmarkPlay size={40} />}
                  />
                </Box>
                {isDragActive ? (
                  <Text fontSize={'sm'} fontWeight="semibold">
                    Drop here!
                  </Text>
                ) : (
                  <Flex>
                    <Text fontSize={'sm'}>
                      Drop screenshot video/GIF file or{' '}
                    </Text>
                    <Button
                      variant={'link'}
                      fontSize="sm"
                      ml={1}
                      color="blue.600"
                    >
                      browse
                    </Button>
                  </Flex>
                )}
                <Text fontSize={'xs'} color="gray.400">
                  Max file size: 20MB
                </Text>
              </Box>
            )}
          </Box>
        </div>
      </Center>
      {actions}
    </Flex>
  );
};

export default Upload;
