import {
  Flex,
  useColorModeValue,
  Link,
  Center,
  Text,
} from '@chakra-ui/react';
import { ReactElement } from 'react';
import router from 'next/router';

const CardListItem = ({
  url,
  title,
  caption,
  leftImage,
  rightContent,
  badge,
  size = 'md',
  onClick = () => null
}: {
  url?: string;
  title: string;
  caption?: string;
  size?: 'sm' | 'md';
  leftImage?: ReactElement;
  rightContent?: ReactElement;
  badge?: ReactElement;
  onClick?: () => void;
}) => {
  const STYLES = {
    sm: {
      styles: {
        px: 4,
        py: 4,
        my: 2
      },
      IMAGE: '24px'
    },
    md: {
      styles: {
        px: 4,
        py: 4,
        my: 2
      },
      IMAGE: '40px'
    }
  }
  return (
    <Link
      w="100%"
      maxW={600}
      {...STYLES[size].styles}
      bg={useColorModeValue('white', 'gray.800')}
      _hover={{
        textDecoration: 'none',
        boxShadow: 'lg',
        transform: 'translateY(-2px)'
      }}
      boxShadow={'md'}
      rounded={'lg'}
      onClick={() => {
        if (url) router.push(url, undefined, { shallow: true });
        onClick();
        return false;
      }}
      href={url}
    >
      <Flex wrap="nowrap">
        {leftImage && (
          <Center
            flex={`0 0 ${STYLES[size].IMAGE}`}
            rounded={'lg'}
            height={STYLES[size].IMAGE}
            width={STYLES[size].IMAGE}
          >
            {leftImage}
          </Center>
        )}
        <Flex direction="column" pl={2} pt={0} align={'left'} w="100%">
          <Flex justify="space-between" alignItems={'center'}>
            <Flex direction={'column'}>
              <Flex>
                <Text
                  fontSize={'md'}
                  fontWeight="semibold"
                  color="gray.600"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  overflow="hidden"
                >
                  {title}
                </Text>
                {badge}
              </Flex>
              {caption && (
                <Text fontSize="sm" color="gray.500">
                  {caption}
                </Text>
              )}
            </Flex>
            {rightContent && <Flex>{rightContent}</Flex>}
          </Flex>
        </Flex>
      </Flex>
    </Link>
  );
};

export default CardListItem;
