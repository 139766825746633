import { FC, memo, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  FormErrorMessage,
  FormControl,
  Input,
  FlexProps,
  Text,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  Badge
} from '@chakra-ui/react';
import { Collection } from '@workgood/types';
import { BiSearch } from 'react-icons/bi';
import { AppService } from 'services/AppService';
import {
  ApolloClient,
  NormalizedCacheObject,
  useApolloClient
} from '@apollo/client';
import { AppContext } from 'utils/contexts/AppContext';
import { useRouter } from 'next/router';

interface CollectionProps {
  onSearch?: (results: Collection[], showEmpty: boolean) => void;
}

export const CollectionSearch: FC<FlexProps & CollectionProps> = memo(
  ({ onSearch }) => {
    const { app } = useContext(AppContext);
    const { asPath, pathname } = useRouter();
    const client = useApolloClient() as ApolloClient<NormalizedCacheObject>;
    const [result, setResult] = useState([]);
    const [term, setTerm] = useState('');
    const {
      handleSubmit,
      register,
      reset,
      formState: { errors, isDirty }
    } = useForm();

    const resetForm = () => {
      reset({ collectionSearch: '' });
      setTerm('');
      onSearch([], false);
      setResult([]);
    };

    useEffect(() => {
      resetForm();
    }, [asPath, pathname]);

    function onSubmit({ collectionSearch }) {
      return new Promise((resolve) => {
        if (~~collectionSearch?.length === 0) {
          onSearch([], false);
          return;
        }

        AppService.searchCollections(client, `%${collectionSearch}%`, app.id)
          .then((result) => {
            onSearch(result, result.length === 0);
            setResult(result);
            resolve(result);
          })
          .catch((e) => onSearch([], false));
      });
    }
    return (
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ width: '100%', height: '21px' }}
      >
        <FormControl isInvalid={errors.collectionSearch}>
          <InputGroup variant={'unstyled'} alignItems="center">
            <InputLeftElement
              pl={0}
              mr={2}
              pointerEvents="none"
              position={'relative'}
              children={
                <Text color="gray.400">
                  <BiSearch />
                </Text>
              }
            />
            <Input
              id="collectionSearch"
              placeholder="Search Collections"
              size="sm"
              fontWeight={'semibold'}
              color="gray.300"
              autoComplete="off"
              _placeholder={{ fontWeight: 'semibold', color: 'gray.400' }}
              {...register('collectionSearch', {
                minLength: {
                  value: 3,
                  message: 'Should have at least 3 letters'
                },
                onChange: (e) => {
                  setTerm(e.target.value);
                  if (!e.target.value.length) resetForm();
                }
              })}
            />
          </InputGroup>
          {isDirty && !result.length ? (
            <InputRightElement width="4.5rem">
              <Badge
                variant="outline"
                colorScheme="gray"
                color="gray.400"
                mr={6}
                cursor="pointer"
                onClick={resetForm}
              >
                PRESS ENTER
              </Badge>
            </InputRightElement>
          ) : (
            term?.length > 0 && (
              <InputRightElement width="4.5rem">
                <Badge
                  variant="outline"
                  colorScheme="gray"
                  color="gray.400"
                  mr={6}
                  cursor="pointer"
                  onClick={resetForm}
                >
                  CLEAR
                </Badge>
              </InputRightElement>
            )
          )}
          <FormErrorMessage>
            <Text color="red.200">
              {errors.collectionSearch && errors.collectionSearch.message}
            </Text>
          </FormErrorMessage>
        </FormControl>
      </form>
    );
  }
);

export default CollectionSearch;
