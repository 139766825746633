import { Collection } from '@workgood/types';
import Image from 'next/image';
import {
  Flex,
  useColorModeValue,
  Link,
  HStack,
  Center,
  Text,
  Box
} from '@chakra-ui/react';
import { getImageUrl } from 'utils/image';
import router from 'next/router';
import { BsChevronRight } from 'react-icons/bs';

export default function CollectionItem({
  url,
  collection,
  showAppLogo = false,
}: {
  url: string;
  showAppLogo?: boolean;
  collection: Collection;
}) {
  return (
    <Link
      w="100%"
      maxW={600}
      my={2}
      p={4}
      bg={useColorModeValue('white', 'gray.800')}
      _hover={{
        textDecoration: 'none',
        boxShadow: 'lg',
        transform: 'translateY(-2px)'
      }}
      boxShadow={'md'}
      rounded={'lg'}
      onClick={(e) => {
        analytics.track('Explore Collection Item Clicked', {
          name: collection.name,
          app: collection.App?.name
        });
        router.push(url, undefined, { shallow: true });
      }}
      href={url}
    >
      <Flex wrap="nowrap">
        {showAppLogo && (
          <Center
            flex={'0 0 40px'}
            rounded={'lg'}
            height={'40px'}
            width={'40px'}
          >
            <Image
              height={32}
              width={32}
              objectFit={'cover'}
              src={getImageUrl(`logos/${collection.App.slug}.png`)}
              alt={`${collection.name}`}
            />
          </Center>
        )}
        <Flex direction="column" pl={2} pt={0} align={'left'} w="100%">
          <Flex justify="space-between" alignItems={'center'}>
            <Flex direction={'column'}>
              <Text
                fontSize={'md'}
                fontWeight="semibold"
                color="gray.600"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
              >
                {collection.name}
              </Text>
              <Text fontSize="sm" color="gray.500">
                {collection?.Collection_Features_aggregate?.aggregate?.count ?? 0} shortcuts
              </Text>
            </Flex>
            <Box display={['block', 'none']}>
              <BsChevronRight />
            </Box>
          </Flex>
          {collection?.difficulty && (
            <Flex justify="space-between" color="gray.500" mt={1}>
              <HStack fontSize="xs">
                <Flex align="center" justify="flex-end"></Flex>
              </HStack>
            </Flex>
          )}
        </Flex>
      </Flex>
    </Link>
  );
}
