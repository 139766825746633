import { FC } from 'react';
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  Tooltip
} from '@chakra-ui/react';
import { Binding } from 'components/atoms';
import { FaUpload } from 'react-icons/fa';
import { Feature, Feature_Preview } from '@workgood/types';
import get from 'lodash/get';
import Image from 'next/image';
import FeatureStat from './FeatureStat';
import { getPlatform } from 'utils/device';

interface ShortcutsProps {
  feature?: Feature;
  isOpen?: boolean;
  onClose?: () => void;
}

export const FeatureModal: FC<ShortcutsProps> = ({
  feature,
  isOpen,
  onClose = () => null
}) => {
  const preview = get(feature, 'Previews.0') as Feature_Preview;
  const previewWidth = 500;
  const { height, width } = preview ?? { height: 150, width: previewWidth };
  const previewHeight = ~~((height * previewWidth) / width);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={'3xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Center>
            <Flex direction={'column'} mt={4}>
              <Box mb={4}>
                <Center
                  width={720}
                  height={457}
                  mb={2}
                  bg="gray.100"
                  border="1px solid"
                  borderColor="gray.300"
                  borderRadius="6px"
                >
                  {preview?.file_name ? (
                    <Box
                      position={'relative'}
                      height={previewHeight}
                      width={previewWidth}
                      border="1px solid"
                      borderColor="gray.300"
                      borderRadius="6px"
                      boxShadow={'md'}
                    >
                      <Image
                        src={`features/${preview.file_name}`}
                        layout="fill"
                      />
                    </Box>
                  ) : (
                    <Box py={8}>
                      <Text
                        fontSize={'sm'}
                        fontWeight="semibold"
                        color="gray.600"
                      >
                        No Preview Available
                      </Text>
                      <Box color="gray.400" mt={2}>
                        <Tooltip
                          label={
                            <Box>
                              <Text fontSize={'xs'}>
                                Earn 2 practice credits
                              </Text>
                            </Box>
                          }
                          fontSize="sm"
                        >
                          <Button
                            aria-label="Submit Preview"
                            color="gray.400"
                            _hover={{
                              color: 'gray.600'
                            }}
                            size={'sm'}
                            variant={'link'}
                            colorScheme={'blue'}
                            leftIcon={<FaUpload size={16} />}
                          >
                            <Text fontSize={'xs'}>Submit Preview</Text>
                          </Button>
                        </Tooltip>
                      </Box>
                    </Box>
                  )}
                  {/* <Image
                    src="https://res.cloudinary.com/sharply/image/upload/v1651830163/shortcuts/crqhn0h0iehsshmtu0x8.webp"
                    border="1px solid"
                    borderColor="gray.300"
                    borderRadius="6px"
                    boxShadow={'md'}
                  /> */}
                </Center>
                <Flex justify={'space-between'}>
                  <FeatureStat
                    feature={feature}
                    showLabel={true}
                    color="gray.500"
                    size="md"
                  />
                  {/* <Flex align={'center'}>
                    <Tooltip
                      label={
                        <Box>
                          <Text>Submit a Preview</Text>
                          <Text fontSize={'xs'}>Earn 2 practice credits</Text>
                        </Box>
                      }
                      fontSize="sm"
                    >
                      <IconButton
                        aria-label="Submit a Preview"
                        color="gray.400"
                        _hover={{
                          color: 'gray.600'
                        }}
                        size={'sm'}
                        variant={'link'}
                        colorScheme={'blue'}
                        icon={<FaUpload size={20} />}
                      ></IconButton>
                    </Tooltip>
                    <Tooltip
                      label={
                        <Box>
                          <Text>Submit a Correction</Text>
                          <Text fontSize={'xs'}>Earn 2 practice credits</Text>
                        </Box>
                      }
                      fontSize="sm"
                    >
                      <IconButton
                        aria-label="Submit a Correction"
                        color="gray.400"
                        _hover={{
                          color: 'gray.600'
                        }}
                        size={'sm'}
                        variant={'link'}
                        colorScheme={'blue'}
                        icon={<RiErrorWarningFill size={20} />}
                        ml={1}
                      ></IconButton>
                    </Tooltip>
                  </Flex> */}
                </Flex>
              </Box>
              <Flex mb={6} justify="space-between" alignItems={'center'}>
                <Box maxW={500} flex={3}>
                  <Heading size={'md'}>Expand Selection</Heading>
                  <Text color="gray.600">
                    The scope of selection expands every time you use this
                    shortcut. The scope of selection expands every time you use
                    this shortcut.
                  </Text>
                </Box>
                <Flex direction="column" align={'center'} flex={1}>
                  <Binding
                    size="md"
                    feature={
                      {
                        name: '',
                        mac_binding: 'command+c',
                        win_binding: 'ctrl+c'
                      } as Feature
                    }
                    platform={getPlatform()}
                  />
                </Flex>
              </Flex>
            </Flex>
          </Center>
        </ModalBody>
        <ModalFooter>
          <Button variant={'outline'} mr={4} onClick={onClose}>
            Close
          </Button>
          {/* <Button colorScheme={'blue'} leftIcon={<FaFolderPlus size={20} />}>
            Save to Group
          </Button> */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FeatureModal;
