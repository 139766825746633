import { FC, useContext } from 'react';
import { IconButton } from '@chakra-ui/react';
import { RiStarFill } from 'react-icons/ri';
import { Access } from 'modules/shared/acl';
import { UserService } from 'services';
import {
  ApolloClient,
  NormalizedCacheObject,
  useApolloClient
} from '@apollo/client';
import { useUser } from 'modules/auth';
import { Collection } from '@workgood/types';
import { AppContext } from 'utils/contexts/AppContext';
import useCollections from './useCollections';

interface CollectionProps {
  isLiked?: boolean;
  collection: Collection;
}

export const StarCollection: FC<CollectionProps> = ({
  isLiked,
  collection
}) => {
  const { app } = useContext(AppContext);
  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>;
  const { user } = useUser();
  const { refetch } = useCollections();

  return (
    <Access
      context={{ user, app, collection }}
      resource="collection"
      action="practice"
      onClick={() => {
        if (isLiked) {
          console.log("refetch after like")
          UserService.unlikeCollection(client, collection.id).then(refetch);
        } else {
          console.log("refetch after unlike")
          UserService.likeCollection(client, collection.id).then(refetch);
        }
      }}
    >
      {isLiked ? (
        <IconButton
          variant={'link'}
          aria-label="unstar collection"
          icon={<RiStarFill />}
          color="yellow.300"
          _hover={{ color: '#888 !important' }}
        />
      ) : (
        <IconButton
          variant={'link'}
          aria-label="star collection"
          icon={<RiStarFill />}
          color="transparent"
          _groupHover={{ color: 'gray.500' }}
          _hover={{ color: '#EEE !important' }}
        />
      )}
    </Access>
  );
};

export default StarCollection;
