import { useContext, useEffect, useMemo, useState } from 'react';
import {
  ApolloClient,
  NormalizedCacheObject,
  useApolloClient
} from '@apollo/client';
import { useUser } from 'modules/auth';
import { UserService } from 'services';
import { App } from '@workgood/types';
import { AppContext } from 'utils/contexts/AppContext';
import { isServer } from 'utils/env';
import { localGetUserApps } from 'utils/localDb';
import { keyBy } from 'lodash';

type UseAppsReturn = {
  myApps: App[];
  myAppsIndex: Record<string, App>;
  loading: boolean;
  refetch: () => Promise<void>;
  addApp: (app: App) => Promise<any>;
  removeApp: (app: App) => Promise<any>;
};

export const useApps = (): UseAppsReturn => {
  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>;
  const [loading, setLoading] = useState(false);
  const { getApp, myApps, setMyApps, myAppsIndex, setMyAppsIndex } = useContext(
    AppContext
  );
  const { user, isAuthenticated } = useUser();

  const fetchLocal = async () => {
    try {
      setLoading(true);
      const userAppsData = await localGetUserApps();
      const userApps = userAppsData.map(({ app_id }) => getApp(app_id));
      setMyApps(userApps);
      setMyAppsIndex(keyBy(userApps, 'app_id'));
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setMyApps([]);
      setMyAppsIndex({});
    }
  };

  const fetch = async () => {
    try {
      if (isServer) return;
      setLoading(true);

      console.log('isAuthenticated', isAuthenticated)
      if (!isAuthenticated) return await fetchLocal();

      const userAppsData = await UserService.getUserApps(client);
      const userApps = userAppsData.map(({ app_id }) => getApp(app_id));
      setMyApps(userApps);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setMyApps([]);
      setMyAppsIndex({});
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      fetchLocal();
    } else {
      fetch();
    }
  }, [isAuthenticated]);

  const addApp = (app: App) => {
    if (!app) return;
    return UserService.addUserApp(client, app?.id).then(fetch);
  };
  const removeApp = (app: App) => {
    if (!app) return;
    return UserService.removeUserApp(client, app?.id, user?.id).then(fetch);
  };

  return { myApps, myAppsIndex, refetch: fetch, loading, addApp, removeApp };
};

export default useApps;
