import UA from 'ua-parser-js';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { Config } from '@workgood/types';
import { GET_CONFIG } from 'utils/queries';

export class ConfigService {
  client: ApolloClient<NormalizedCacheObject>;
  ua: UAParser;
  config: Config[];

  constructor(_client: ApolloClient<NormalizedCacheObject>) {
    this.client = _client;
    this.ua = new UA();
  }

  async getConfig(
    client: ApolloClient<NormalizedCacheObject>
  ): Promise<Config[]> {
    const platform = this.ua.getOS()?.name;
    const userClient = this.ua.getBrowser()?.name;

    try {
      const { data } = await client.query({
        query: GET_CONFIG,
        variables: { client: userClient, platform }
      });
      this.config = data.Config as Config[];
      return this.config;
    } catch {
      return [];
    }
  }
}

export default ConfigService;
