import { FC } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import AppContentHeader from '../AppContentHeader';
import { BigFooter } from 'components/molecules';

interface ShortcutsProps {
  selected?: string;
  width?: string;
  header?: React.ReactNode;
  showFooter?: boolean;
}

export const Content: FC<ShortcutsProps> = ({
  header,
  children,
  width,
  showFooter = true
}) => {
  const maxWidth = width === 'full' ? '100%' : 600;
  return (
    <Flex
    direction={'column'}
      pt={{ base: 3, md: 6 }}
      px={{ base: 0, md: 0 }}
      pb={{ _: 20, sm: 0 }}
      h="100%"
      w="100%"
    >
      {header ? header : <AppContentHeader />}
      <Flex
        direction="column"
        align="center"
        w="100%"
        minH={'710px'}
        m="auto"
        pb={20}
        overflowY={'auto'}
      >
        {children}
      </Flex>
      {showFooter && (
        <Box justifySelf={{base: 'auto', md: 'flex-end'}}>
          <BigFooter />
        </Box>
      )}
    </Flex>
  );
};

export default Content;
