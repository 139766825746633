import { FC, useContext } from 'react';
import { AppContext } from 'utils/contexts/AppContext';
import { App, Collection } from '@workgood/types';
import { getCollectionTitle } from 'utils/helpers';
import ContentHeader from '../ContentHeader';

interface ContentHeaderProps {
  actions?: React.ReactNode;
}

export const AppContentHeader: FC<ContentHeaderProps> = ({ actions }) => {
  const { app = {} as App, collection = {} as Collection } = useContext(
    AppContext
  );
  return (
    <ContentHeader
      title={getCollectionTitle({collectionName: collection.name, appendShortcuts: true})}
      subTitle={`for ${app.name}`}
      actions={actions}
    />
  );
};

export default AppContentHeader;
