import { FC, useState } from 'react';
import {
  Box,
  IconButton,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal
} from '@chakra-ui/react';
import { Feature, Feature_Preview } from '@workgood/types';
import Preview from './views/Preview';
import UploadPreview from './views/Upload';
import SaveToCollection from './views/SaveToCollection';
import { RiCloseLine } from 'react-icons/ri';

interface ShortcutsProps {
  feature?: Feature;
  onExpandClick?: (feature: Feature) => void;
}

export enum PreviewMode {
  Image = 'image',
  Collection = 'collection',
  Report = 'report',
  Upload = 'upload'
}

export const FeaturePreview: FC<ShortcutsProps> = ({
  feature,
  children,
  onExpandClick = () => null
}) => {
  const [previewMode, setPreviewMode] = useState<PreviewMode>(
    PreviewMode.Collection
  );
  let preview;

  // switch (previewMode) {
  //   case PreviewMode.Collection:
  //     preview = <AddToCollection feature={feature} />;
  //     break;
  //   case PreviewMode.Report:
  //     break;
  //   case PreviewMode.Upload:
  //     preview = <PreviewUploader feature={feature} />;
  //     break;
  //   default:
  //     preview = (
  //       <ImagePreview
  //         feature={feature}
  //         onSubmitPreviewClick={() => setPreviewMode(PreviewMode.Upload)}
  //       />
  //     );
  // }

  // const backButton = (
  //   <Button
  //     mr={2}
  //     aria-label="Back"
  //     color="gray.500"
  //     _hover={{
  //       color: 'gray.600'
  //     }}
  //     size={'sm'}
  //     variant={'link'}
  //     colorScheme={'blue'}
  //     onClick={() => setPreviewMode(PreviewMode.Image)}
  //     leftIcon={<IoMdArrowRoundBack size={20} />}
  //   >
  //     Back
  //   </Button>
  // );

  switch (previewMode) {
    case PreviewMode.Image:
      preview = (
        <Preview onPreviewModeChange={setPreviewMode} feature={feature} />
      );
      break;
    case PreviewMode.Upload:
      preview = (
        <UploadPreview onPreviewModeChange={setPreviewMode} feature={feature} />
      );
      break;
    case PreviewMode.Collection:
      preview = (
        <SaveToCollection
          onPreviewModeChange={() => null}
          feature={feature}
        />
      );
      break;
    default:
      // actions = imagePreviewActions;
      break;
  }

  return (
    <Popover
      closeOnBlur={true}
      closeOnEsc={true}
      isLazy={true}
      // onClose={() => setPreviewMode(PreviewMode.Image)}
    >
      {({ onClose }) => (
        <>
          <PopoverTrigger>{children}</PopoverTrigger>
          <Portal>
            <PopoverContent
              bg={'gray.200'}
              color="white"
              border="1px solid #CCC !important"
              outline="none !important"
            >
              <PopoverBody position={'relative'}>
                <Box
                  fontSize={'sm'}
                  color="gray.500"
                  fontWeight={'semibold'}
                  margin='auto'
                  maxW={'240px'}
                  whiteSpace='nowrap'
                  overflow='hidden'
                  textOverflow='ellipsis'
                  textAlign={'center'}
                >
                  {feature.name}
                </Box>
                <IconButton
                  top={2}
                  right={1}
                  position="absolute"
                  color={'gray.500'}
                  variant="link"
                  icon={<RiCloseLine size={20} />}
                  aria-label={'Close feature preview'}
                  onClick={onClose}
                />
                <Box mb={0} mt={1}>
                  {preview}
                </Box>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
};

export default FeaturePreview;
