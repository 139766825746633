import { FC, useState } from 'react';
import { useRouter } from 'next/router';
import { App } from '@workgood/types';
import {
  Link,
  Text,
  Flex,
  Box,
  ScaleFade,
  Button,
  Stack
} from '@chakra-ui/react';
import { buildUrl, parseUrl } from 'utils/route';
import { getImageUrl } from 'utils/image';
import { AppLogo } from 'components/atoms';
import StarApp from './StarApp';
import { get } from 'lodash';
interface AppListProps {
  apps: App[];
  title: string;
  isLiked?: boolean;
  initialLimit?: number;
}

export const AppList: FC<AppListProps> = ({
  apps = [],
  title,
  isLiked = false,
  initialLimit = 0
}) => {
  const [limit, setLimit] = useState(initialLimit);
  const router = useRouter();
  const { platform, appSlug } = parseUrl(router.query?.slug, router.pathname);
  const appsToShow = limit ? apps.slice(0, limit) : apps;
  const showSeeAllButton = apps?.length > limit && limit;

  return (
    <>
      <Stack spacing={0}>
        <Flex
          role="group"
          alignItems={'center'}
          justify="space-between"
          cursor="pointer"
          pb={2}
          pl={4}
          display={{ base: 'none', md: 'flex' }}
        >
          <Text
            display={{ sm: 'none', lg: 'block' }}
            w="80%"
            fontSize={'xs'}
            color={['gray.400', 'gray.400']}
            fontWeight={'semibold'}
            textTransform="uppercase"
          >
            {title}
          </Text>
          <Button
            display={showSeeAllButton ? 'block' : 'none'}
            variant={'link'}
            colorScheme={'whiteAlpha'}
            w={50}
            size="xs"
            color="gray.300"
            mr={4}
            opacity={0.3}
            _groupHover={{ opacity: 1 }}
            onClick={() => setLimit(0)}
          >
            See All
          </Button>
        </Flex>
        <Box>
          {appsToShow
            .map((app) => ({
              app,
              active: app.slug === appSlug,
              url: buildUrl({
                platform,
                appSlug: app.slug,
                collectionName: 'Top Shortcuts',
                collectionId: get(app, 'Collections[0].id')
              })
            }))

            .map(({ app, url, active }, index) => (
              <Link
                role="group"
                _focus={{ outline: '0', boxShadow: 'none' }}
                key={app.name}
                borderRadius={1}
                onClick={(e) => {
                  e.preventDefault();
                  analytics.track('App Item Clicked', {
                    app: app.name,
                    category: app.category
                  });
                  router.push(url, undefined, { shallow: true });
                }}
                href={url}
              >
                <Flex
                  alignItems={'center'}
                  justify={{ base: 'center', md: 'flex-start' }}
                  width="100%"
                  opacity={isLiked ? 1 : 0.75}
                  _groupHover={{ opacity: 1, bg: 'brand.700' }}
                  py={3}
                  px={4}
                  bg={active ? 'brand.600' : 'none'}
                >
                  <AppLogo
                    params={['c_pad']}
                    dimensions={[28, 28]}
                    src={getImageUrl(`logos/${app.slug}.png`)}
                    alt={`${app.name}`}
                  />
                  <Flex
                    justifyContent={'space-between'}
                    w="100%"
                    display={{ sm: 'none', md: 'none', lg: 'flex' }}
                  >
                    <Text
                      color={active ? 'white' : 'gray.400'}
                      _groupHover={{ color: 'white' }}
                      ml={2}
                      fontWeight="semibold"
                    >
                      {app.name}
                    </Text>
                  </Flex>
                  <StarApp app={app} isLiked={isLiked} />
                </Flex>
              </Link>
            ))}
        </Box>
      </Stack>
    </>
  );
};

export default AppList;
