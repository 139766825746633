import { FC, useState } from 'react';
import { Box, Flex, IconButton, Text, Tooltip } from '@chakra-ui/react';
import { Feature } from '@workgood/types';
import { UserService } from 'services';
import {
  ApolloClient,
  NormalizedCacheObject,
  useApolloClient
} from '@apollo/client';
import { RiThumbUpFill } from 'react-icons/ri';
import { useUser } from 'modules/auth';
import find from 'lodash/find';
import { Access } from 'modules/shared/acl';

interface LikeButtonProps {
  feature?: Feature;
  label?: string;
  size?: string;
  fontSize?: string;
  postText?: string;
  hideTooltip?: boolean;
}

export const LikeButton: FC<LikeButtonProps> = ({
  feature,
  label,
  size = 'sm',
  fontSize,
  postText = 'Like',
  hideTooltip = false
}) => {
  if (!feature) return <></>;

  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>;
  const { user } = useUser();
  const [isLiked, setIsLiked] = useState<boolean>(
    !!find(user?.Feature_Likes, ({ feature_id }) => feature_id === feature.id)
  );

  const handleLikeClick = () => {
    if (isLiked) {
      UserService.unlikeFeature(client, feature.id).then(() =>
        setIsLiked(false)
      );
    } else {
      UserService.likeFeature(client, feature.id).then(() => setIsLiked(true));
    }
  };

  const iconSizeMap = {
    xs: 16,
    smaller: 16,
    small: 20,
    sm: 20,
    md: 24
  };

  const displayLabel = label ? label : isLiked ? 'Unlike' : 'Like';
  return (
    <Access
      context={{ user }}
      resource="feature"
      action="star"
      onClick={handleLikeClick}
    >
      <Tooltip
        isDisabled={hideTooltip}
        bg="blue.800"
        label={
          <Box>
            <Text>{displayLabel}</Text>
          </Box>
        }
        fontSize="sm"
      >
        <Flex alignItems={'center'} >
          <IconButton
            aria-label={displayLabel}
            color={isLiked ? 'blue.500' : 'gray.500'}
            _hover={{
              color: 'gray.600'
            }}
            size={size}
            variant={'link'}
            colorScheme={'blue'}
            icon={<RiThumbUpFill size={iconSizeMap[size]} />}
          ></IconButton>
          {postText && (
            <Text color="gray.500" fontSize={'smaller'}>
              {postText}
            </Text>
          )}
        </Flex>
      </Tooltip>
    </Access>
  );
};

export default LikeButton;
