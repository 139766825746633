import { useMemo, useState } from 'react';
import { App } from '@workgood/types';
import {
  Box,
  Divider,
  Flex,
  Input,
  InputGroup,
  InputLeftElement
} from '@chakra-ui/react';
import AppContent from 'modules/shared/templates/AppContent';
import { APP_CATEGORIES, LITERALS } from 'utils/constants';
import AppCategoryList from './AppCategoryList';
import { BiSearch } from 'react-icons/bi';
import useApps from 'modules/navigation/components/Apps/useApps';

export function AppSelect({ apps }: { apps: App[] }) {
  const [search, setSearch] = useState<string>('');
  const { myApps } = useApps();

  const searchResults = useMemo(
    () =>
      apps.filter(
        (app) => ~app.name.toLowerCase().indexOf(search.toLowerCase())
      ),
    [search, apps]
  );

  return (
    <AppContent header={<div />} width="100%">
      <Box w="100%" mb={4} key="app-select">
        <Flex
          key="app-select-content"
          flex={1}
          direction={'column'}
          justify={'flex-end'}
          alignItems="center"
          mt={0}
          px={4}
          style={{ width: '100%' }}
        >
          <Flex
            maxW={600}
            mt={4}
            direction="column"
            align="center"
            w="100%"
            style={{ paddingInlineEnd: 0, paddingInlineStart: 0 }}
          >
            <InputGroup
              mb={8}
              width={{ base: '100%', md: '288px' }}
              alignSelf="flex-end"
            >
              <InputLeftElement
                color="gray.300"
                pointerEvents="none"
                children={<BiSearch />}
              />
              <Input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search Apps"
              />
            </InputGroup>

            {/* Search Results */}
            {search?.length > 1 && searchResults ? (
              <>
                <AppCategoryList
                  apps={searchResults ?? []}
                  title={'Search Results'}
                  initialLimit={0}
                />
                <Divider orientation="horizontal" color={'gray.500'} my={4} />
              </>
            ) : (
              <>
                {/* Liked Apps */}
                {!!myApps?.length && (
                  <>
                    <AppCategoryList
                      apps={myApps ?? []}
                      title={LITERALS.LIKED_APPS}
                      initialLimit={0}
                    />
                    <Divider
                      orientation="horizontal"
                      color={'gray.500'}
                      my={4}
                    />
                  </>
                )}
                {/* All Apps */}
                {APP_CATEGORIES.map(({ title, filter }) => (
                  <AppCategoryList apps={filter(apps) ?? []} title={title} />
                ))}
              </>
            )}
          </Flex>
        </Flex>
      </Box>
    </AppContent>
  );
}

export default AppSelect;
