import { FC, useContext, useState } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  Flex,
  Heading,
  Input,
  Stack,
  Text
} from '@chakra-ui/react';
import {
  Collection,
  Collection_Feature,
  Collection_Insert_Input,
  Feature
} from '@workgood/types';
import {
  ApolloClient,
  NormalizedCacheObject,
  useApolloClient,
  useQuery
} from '@apollo/client';
import { useUser } from 'modules/auth';
import { AppService, UserService } from 'services';
import { GET_MY_APP_COLLECTIONS } from 'utils/queries';
import { AppContext } from 'utils/contexts/AppContext';
import { PreviewMode } from '../FeaturePreview';
import { BackButton } from '../shared/BackButton';
import Access from 'modules/shared/acl/Access';

interface SaveToCollectionProps {
  feature?: Feature;
  onPreviewModeChange: (mode: PreviewMode) => void;
}

export const SaveToCollection: FC<SaveToCollectionProps> = ({
  feature,
  onPreviewModeChange
}) => {
  const { app } = useContext(AppContext);
  const [newCollectionName, setNewCollectionName] = useState<string>('');
  const [isPublished, setIsPublished] = useState<boolean>(
    false
  );
  const [isValid, setIsValid] = useState<boolean>(true);
  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>;
  const { user, refetch: refetchUser } = useUser();
  const { data, refetch } = useQuery<{ Collection: Collection[] }>(
    GET_MY_APP_COLLECTIONS,
    { variables: { user_id: user?.id, app_id: app?.id } }
  );
  const collections = data?.Collection ?? [];

  const handleCollectionToggle = (
    collection: Collection,
    e: React.FormEvent<HTMLInputElement>
  ) => {
    if ((e.target as any).checked) {
      UserService.addFeatureToCollection(client, {
        collection_id: collection.id,
        feature_id: feature.id
      } as Collection_Feature).then(() => {
        refetch();
        AppService.getCollection(client, collection.id, true);
      });
    } else {
      UserService.removeCollectionFeature(
        client,
        collection.id,
        feature.id
      ).then(() => {
        refetch();
        AppService.getCollection(client, collection.id, true);
      });
    }
  };

  const handleSaveToNewCollection = () => {
    const newCollection: Collection_Insert_Input = {
      name: newCollectionName,
      app_id: app.id,
      is_published: isPublished,
      Collection_Features: {
        data: [{ feature_id: feature.id }]
      }
    };
    UserService.addCollection(client, newCollection).then(() => {
      refetch();
      refetchUser();
      setNewCollectionName('');
    });
  };

  const actions = (
    <Flex justifyContent={'space-between'}>
      {/* <BackButton onClick={onPreviewModeChange} /> */}
    </Flex>
  );

  return (
    <Flex direction={'column'}>
      <Flex
        direction={'column'}
        alignItems="flex-start"
        bg="gray.50"
        border="1px solid"
        borderColor="gray.300"
        borderRadius="6px"
        mb={2}
        px={4}
        py={2}
        w={'100%'}
      >
        {collections.length ? (
          <>
            <Stack>
              <Heading size={'xs'} color="gray.600" my={2} textAlign="left">
                Save to collection
              </Heading>
              {collections.map((collection) => (
                <Checkbox
                  size="md"
                  colorScheme="green"
                  color="gray.600"
                  onChange={(e) => handleCollectionToggle(collection, e)}
                  isChecked={
                    !!collection.Collection_Features.find(
                      ({ feature_id }) => feature_id === feature.id
                    )
                  }
                >
                  <Text fontSize={'sm'}>{collection.name}</Text>
                </Checkbox>
              ))}
            </Stack>
            <Divider w="100%" mt={4}></Divider>
          </>
        ) : (
          <></>
        )}
        <Heading size={'xs'} color="gray.600" my={3}>
          {`${
            collections.length > 0 ? 'Create new' : 'Save to new'
          } collection`}
        </Heading>
        <Input
          size={'sm'}
          placeholder="Collection Name"
          mb={2}
          value={newCollectionName}
          isInvalid={!isValid}
          onChange={(e) => {
            setNewCollectionName(e.target?.value);
            setIsValid(e.target?.value?.length > 2);
          }}
          color="gray.600"
        ></Input>
        <Flex
          w="100%"
          alignItems={'center'}
          justifyContent="space-between"
          mb={2}
        >
          <Flex>
            <Checkbox
              isChecked={isPublished}
              onChange={(e) => setIsPublished(e.target.checked)}
              size="sm"
              spacing={1}
              colorScheme="green"
              color="gray.600"
            >
              Public {isPublished}
            </Checkbox>
          </Flex>

          <Access
            context={{ user }}
            resource="collection"
            action="create"
            onClick={handleSaveToNewCollection}
          >
            <Button
              isDisabled={!isValid || !newCollectionName.length}
              variant={'outline'}
              colorScheme="blue"
              size="xs"
              alignSelf="flex-end"
            >
              CREATE
            </Button>
          </Access>
        </Flex>
      </Flex>
      {actions}
    </Flex>
  );
};

export default SaveToCollection;
