import dynamic from 'next/dynamic';
import { FC, useEffect, useState, useContext } from 'react';
import router, { useRouter } from 'next/router';
import { LogoJsonLd } from 'next-seo';
import * as Sentry from '@sentry/node';
import {
  ApolloClient,
  getApolloContext,
  NormalizedCacheObject
} from '@apollo/client';
import { parseUrl, getMarkedRedirect, getAppView } from 'utils/route';
import { AppContextProvider } from 'utils/contexts/AppContext';
import { App, Collection } from '@workgood/types';
import { AppLayout } from 'components/layouts';
import { Page } from 'components/organisms';

import { DefaultJsonLd } from 'utils/seo';
import { getImageUrl } from 'utils/image';
import AppService from 'services/AppService';
const AppBarButtons = dynamic(() => import('components/molecules/AppBar/components/AppBarButtons'), {
  ssr: false
});
const Shortcuts = dynamic(
  () => import('modules/shortcuts/components/Shortcuts')
);
const Practice = dynamic(() => import('modules/practice/components/Practice'));
const PracticeResults = dynamic(() => import('modules/practice/components/PracticeResults'));
const PracticeProgress = dynamic(
  () => import('modules/practice/components/PracticeProgress')
);
const Default = dynamic(() => import('modules/explore/components/Default'));
const Apps = dynamic(() => import('modules/navigation/components/Apps'), {
  ssr: false
});
const Collections = dynamic(
  () => import('modules/navigation/components/Collections')
);
const AllShortcuts = dynamic(
  () => import('modules/navigation/components/AllShortcuts')
);
const Home = dynamic(() => import('modules/explore/components/Home'));
import { View } from 'utils/constants';
import AppSelect from 'modules/explore/components/AppSelect';
import { Center, Collapse, ScaleFade, Spinner } from '@chakra-ui/react';
import AccessModals from 'modules/shared/acl/AccessModals';
import { AppCollections } from 'modules/collections';
import { getPlatform } from 'utils/device';
import { createOgTitle } from 'modules/og';

interface MainAppProps {
  apps: App[];
  initialApp: App;
  initialCollection: Collection;
  initialCollections: Collection[];
  children: React.ReactNode;
}
export const MainApp: FC<MainAppProps> = ({
  apps = [],
  initialApp,
  initialCollection,
  initialCollections
}) => {
  const {
    query: { slug },
    pathname
  } = useRouter();

  const [app, setApp] = useState(initialApp);
  const [collection, setCollection] = useState(initialCollection);
  Sentry.setContext('explorer', { app, collection });
  const ApolloContext = getApolloContext();
  const { client } = useContext(ApolloContext);
  const [collections, setCollections] = useState(initialCollections);
  const [appLoading, setAppLoading] = useState(false);
  const [collectionLoading, setCollectionLoading] = useState(false);
  const [collectionsLoading, setCollectionsLoading] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const urlParams = parseUrl(slug, pathname);
  const { appSlug, collectionId: cid } = urlParams;
  const view = getAppView(urlParams, pathname);
  const collectionId = cid || collection?.id;

  useEffect(() => {
    if (appSlug && appSlug !== app?.slug) {
      setApp(apps?.find((app) => app.slug === appSlug));
      fetchCollections(appSlug);
    }

    if (typeof document !== 'undefined') {
      const redirectUrl = getMarkedRedirect();
      if (
        redirectUrl &&
        document?.referrer &&
        ~router.asPath.indexOf('#access_token=')
      ) {
        router.push(redirectUrl, null, { shallow: true });
      }
    }
  }, [appSlug]);

  useEffect(() => {
    if (collectionId && collectionId !== collection?.id) {
      fetchCollection(collectionId);
    }
  }, [collectionId]);

  const fetchCollections = (appSlug: string) => {
    setCollectionsLoading(true);
    AppService.getApp(
      client as ApolloClient<NormalizedCacheObject>,
      appSlug
    ).then((response) => {
      setApp(response);
    });
    AppService.getCollections(
      client as ApolloClient<NormalizedCacheObject>,
      appSlug
    ).then((response) => {
      setCollectionsLoading(false);
      setCollections(response);
      fetchCollection(response[0]?.id);
    });
  };

  const fetchCollection = async (cid: string) => {
    setCollectionLoading(true);
    if (!cid) return;
    await AppService.getCollection(
      client as ApolloClient<NormalizedCacheObject>,
      cid
    ).then((response) => {
      setCollection(response);
      setCollectionLoading(false);
    });
  };

  const isCollection = !!collectionId;
  const description = isCollection
    ? collection?.description
    : app?.description ?? '';

  let sidebar = <Apps apps={apps} selected={app?.slug} />;
  let sidebarExtension;
  let content;

  switch (view) {
    case View.PRACTICE:
      content = <Practice />;
      // sidebar = null;
      sidebarExtension = <PracticeProgress />;
      break;
    // case View.PRACTICE:
    //   content = <PracticeResults />;
    //   sidebar = null;
    //   sidebarExtension = null; //<PracticeProgress />;
    //   break;
    case View.COLLECTION:
      content = (
        <>
          {(collection?.id === collectionId && <Shortcuts />) || (
            <Center w="100%" mt={[20, 40]}>
              <Spinner color="brand.600" />
            </Center>
          )}
        </>
      );
      sidebarExtension = (
        <Collections
          app={app}
          collections={collections}
          loading={collectionsLoading}
          selected={collection}
        />
      );
      break;
    case View.SHORTCUTS:
      content = (
        <AllShortcuts
          app={app}
          collections={collections}
          loading={collectionsLoading}
          selected={collection}
        />
      );
      sidebarExtension = (
        <Collections
          app={app}
          collections={collections}
          loading={collectionsLoading}
          selected={collection}
        />
      );
      break;
    case View.APP:
      content = <AppCollections app={app} collections={collections} />;
      sidebarExtension = (
        <Collections
          app={app}
          collections={collections}
          loading={collectionsLoading}
          selected={collection}
        />
      );
      break;

    case View.ADD_APPS:
      sidebar = null;
      content = <AppSelect apps={apps} />;
      break;

    case View.HOME:
    default:
      sidebar = null;
      content = <Home />;
      break;
  }

  const ogParams = {view, appName: app?.name, appSlug: app?.slug, collectionName: collection?.name};
  return (
    <AppContextProvider
      apps={apps}
      app={app}
      collection={collection}
      collections={collections}
      appLoading={appLoading}
      collectionLoading={collectionLoading}
      collectionsLoading={collectionsLoading}
      isDrawerOpen={isDrawerOpen}
      onDrawerOpenChange={setIsDrawerOpen}
    >
      <Page>
        <DefaultJsonLd
          ogParams={ogParams}
          title={createOgTitle({
            type: view,
            text: collection?.name,
            params: { app, collection, platform: getPlatform() }
          })}
        />
        <LogoJsonLd
          logo={getImageUrl('workgood/full-dark-on-transparent.png')}
          url="https://workgood.io"
        />
        <AppLayout
          sidebar={sidebar}
          sidebarExtension={sidebarExtension}
          appBar={<AppBarButtons />}
        >
          {content}
          <AccessModals />
        </AppLayout>
      </Page>
    </AppContextProvider>
  );
};

export default MainApp;
