import { Button } from '@chakra-ui/react';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { PreviewMode } from '../FeaturePreview';

export const BackButton = ({ onClick = (_: PreviewMode) => null }) => (
  <Button
    mr={2}
    aria-label="Back"
    color="gray.500"
    _hover={{
      color: 'gray.600'
    }}
    size={'sm'}
    variant={'link'}
    colorScheme={'blue'}
    onClick={() => onClick(PreviewMode.Image)}
    leftIcon={<IoMdArrowRoundBack size={20} />}
  >
    Back
  </Button>
);
