import { Collection, App } from '@workgood/types';
import router, { useRouter } from 'next/router';
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  InputGroup,
  InputLeftElement,
  Input
} from '@chakra-ui/react';
import { buildUrl, parseUrl } from 'utils/route';
import CollectionItem from './components/CollectionItem';
import useCollections from 'modules/navigation/components/Collections/useCollections';
import CardListItem from '../CardListItem';
import { BsChevronRight } from 'react-icons/bs';
import { useUser } from 'modules/auth';
import { LITERALS } from 'utils/constants';
import { BiSearch } from 'react-icons/bi';
import { useContext, useMemo, useState } from 'react';
import { AppContext } from 'utils/contexts/AppContext';

export default function CollectionList({
  app,
  showAppLogo = false
}: {
  app: App;
  collections: Collection[];
  showAppLogo?: boolean;
}) {
  const { collections } = useContext(AppContext);
  const [search, setSearch] = useState<string>('');
  const {
    query: { slug },
    pathname
  } = useRouter();
  const { platform } = parseUrl(slug, pathname);
  const {
    myCollections,
    likedCollections,
    publicCollections,
    allCollections
  } = useCollections();
  const searchResults = useMemo(
    () =>
      allCollections.filter(
        (collection) =>
          ~collection.name.toLowerCase().indexOf(search.toLowerCase())
      ),
    [search, allCollections]
  );

  const getCollectionItems = (collections) =>
    collections
      .map((collection) => ({
        url: buildUrl({
          platform,
          appSlug: app?.slug,
          collectionId: collection?.id,
          collectionName: collection?.name
        }),
        collection
      }))
      .map(({ collection, url }) => (
        <CollectionItem
          collection={collection}
          url={url}
          showAppLogo={showAppLogo}
        />
      ));

  const handleViewAllShortcutsClick = () =>
    router.push(
      buildUrl({
        platform,
        appSlug: app.slug,
        collectionName: 'all'
      }),
      undefined,
      { shallow: true }
    );

  return (
    <Flex direction="column" align="center" w="100%">
      <Flex
        direction="column"
        align="center"
        w="100%"
        maxW={600}
        px={4}
        key="my-collections-content"
      >
        <InputGroup
          mb={4}
          width={{ base: '100%', md: '288px' }}
          alignSelf="flex-end"
        >
          <InputLeftElement
            color="gray.300"
            pointerEvents="none"
            children={<BiSearch />}
          />
          <Input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search Cheatsheets"
          />
        </InputGroup>
        {search?.length > 1 ? (
          <>
            <Heading
              mt={8}
              fontWeight={500}
              fontSize={18}
              size="sm"
              alignSelf={'flex-start'}
            >
              Search Results
            </Heading>
            {getCollectionItems(searchResults)}
          </>
        ) : (
          <>
            {myCollections?.length > 0 && (
              <>
                <Heading
                  mt={4}
                  fontWeight={500}
                  fontSize={18}
                  size="sm"
                  alignSelf={'flex-start'}
                >
                  My Cheatsheets
                </Heading>
                {getCollectionItems(myCollections)}
              </>
            )}

            {myCollections?.length > 0 && (
              <>
                <Heading
                  mt={8}
                  fontWeight={500}
                  fontSize={18}
                  size="sm"
                  alignSelf={'flex-start'}
                >
                  Public Cheatsheets
                </Heading>
              </>
            )}

            <CardListItem
              url={buildUrl({
                platform,
                appSlug: app?.slug,
                collectionName: 'all'
              })}
              title="View All Shortcuts"
              // caption="24 shortcuts"
              rightContent={<BsChevronRight />}
            />

            {getCollectionItems(likedCollections)}
            {getCollectionItems(publicCollections)}
          </>
        )}
      </Flex>
      {/* </TabPanel>
          <TabPanel w="100%" key='discover-collections'> */}
      <Flex
        direction="column"
        align="center"
        w="100%"
        key="discover-collections-content"
        style={{ paddingInlineEnd: 0, paddingInlineStart: 0 }}
      >
        <Box
          display={myCollections.length ? 'none' : 'block'}
          textAlign="center"
          mt={10}
        >
          <Text fontStyle="italic" color="gray.500" textAlign={'center'}>
            To create a new collection, click on any shortcut and assign to a
            new collection.
          </Text>
          <Button
            size="sm"
            variant={'solid'}
            colorScheme="teal"
            mt={4}
            onClick={handleViewAllShortcutsClick}
          >
            View All Shortcuts
          </Button>
        </Box>
      </Flex>
    </Flex>
  );
}
