import { FC, memo } from 'react';
import { IconButton } from '@chakra-ui/react';
import { RiStarFill } from 'react-icons/ri';
import { Access } from 'modules/shared/acl';
import { useUser } from 'modules/auth';
import { App } from '@workgood/types';
import useApps from './useApps';

interface StarAppProps {
  isLiked?: boolean;
  app: App;
}

export const StarApp: FC<StarAppProps> = memo(({ isLiked = false, app }) => {
  const { user } = useUser();
  const { addApp, removeApp, refetch } = useApps();

  return (
    <Access
      context={{ user, app }}
      resource="app"
      action="star"
      onClick={() => {
        if (isLiked) {
          removeApp(app).then(refetch);
        } else {
          addApp(app).then(refetch);
        }
      }}
    >
      {isLiked ? (
        <IconButton
          variant={'link'}
          aria-label="unstar app"
          icon={<RiStarFill />}
          color="yellow.300"
          opacity={0.1}
          _groupHover={{ opacity: 1 }}
          _hover={{ color: '#888 !important' }}
        />
      ) : (
        <IconButton
          variant={'link'}
          aria-label="star app"
          icon={<RiStarFill />}
          color="transparent"
          _groupHover={{ color: 'gray.500' }}
          _hover={{ color: '#EEE !important' }}
        />
      )}
    </Access>
  );
});

export default StarApp;
