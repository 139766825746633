import { FC } from 'react';
import { AppContent, ContentHeader } from 'modules/shared/templates';
import { CollectionList } from 'components/molecules';
import { App, Collection } from '@workgood/types';

type CollectionListProps = {
  app: App;
  collections: Collection[];
};

export const AppCollections: FC<CollectionListProps> = ({ app, collections }) => {
  return (
    <AppContent
      header={
        <ContentHeader
          title={'Cheatsheets'}
          showLogo={false}
        />
      }
    >
      <CollectionList app={app as App} collections={collections} />
    </AppContent>
  );
};

export default AppCollections;