import { FC } from 'react';
import { Flex } from '@chakra-ui/react';
import HeaderLogoTitle from '../HeaderLogoTitle';

interface ContentHeaderProps {
  title: string;
  subTitle?: string;
  size?: string;
  showLogo?: boolean;
  actions?: React.ReactNode;
  image?: React.ReactNode;
}

export const ContentHeader: FC<ContentHeaderProps> = ({
  title,
  subTitle,
  actions,
  showLogo
}) => {
  return (
    <Flex
      justify={['space-between', 'space-between']}
      width="100%"
      my={4}
      px={[4, 10]}
      display="flex"
    >
      <HeaderLogoTitle title={title} subTitle={subTitle} showLogo={showLogo} />
      {actions}
    </Flex>
  );
};

export default ContentHeader;
