import { FC, memo, useContext, useMemo } from 'react';
import { Box, Flex, Text, Heading } from '@chakra-ui/react';
import { AppContext } from 'utils/contexts/AppContext';
import { App } from '@workgood/types';
import { AppLogo } from 'components/atoms';

interface ContentHeaderProps {
  title: string;
  subTitle?: string;
  showLogo?: boolean;
}

export const HeaderLogoTitle: FC<ContentHeaderProps> = ({
  title,
  subTitle,
  showLogo = true
}) => {
  const { app = {} as App } = useContext(AppContext);
  return useMemo(
    () => (
      <Flex align="flex-start">
        {showLogo && (
          <Box display={['block', 'none', 'block']} mr={[3, 4]}>
            <AppLogo
              dimensions={[40, 40]}
              params={['c_pad']}
              src={`/logos/${app?.slug}.png`}
              alt={`${app?.name} logo`}
            />
          </Box>
        )}

        <Box>
          <Heading size="md" maxWidth="3xl">
            {title}
          </Heading>
          <Text display={['inline', 'none', 'inline']}>{subTitle}</Text>
        </Box>
      </Flex>
    ),
    [app]
  );
};

export default HeaderLogoTitle;
