import {
  ApolloClient,
  NormalizedCacheObject,
  useApolloClient
} from '@apollo/client';
import { Collection } from '@workgood/types';
import filter from 'lodash/filter';
import { useUser } from 'modules/auth';
import { useContext, useEffect, useMemo, useState } from 'react';
import { UserService } from 'services';
import { AppContext } from 'utils/contexts/AppContext';
import { isServer } from 'utils/env';
import { localGetLikedCollections } from 'utils/localDb';

type UseCollectionReturn = {
  loading: boolean;
  refetch: () => void;
  allCollections: Collection[];
  popularCollections: Collection[];
  myCollections: Collection[];
  likedCollections: Collection[];
  publicCollections: Collection[];
};

export const useCollections = (): UseCollectionReturn => {
  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>;
  const { user, isAuthenticated } = useUser();
  const [loading, setLoading] = useState(false);
  const {
    app,
    collections,
    getCollection,
    myCollections,
    likedCollections,
    setMyCollections,
    setLikedCollections
  } = useContext(AppContext);

  const fetchLocalLikedColletions = async () => {
    try {
      setLoading(true);
      const collectionLikesData = await localGetLikedCollections();

      const likes = collectionLikesData
        .filter((like) => collections.find((c) => c.id === like?.collection_id))
        .map(({ collection_id }) => getCollection(collection_id));
      setLikedCollections(likes);
      setLoading(false);
    } catch (e) {
      setLikedCollections([]);
      setLoading(false);
    }
  };

  const fetchLikedCollections = async () => {
    try {
      if (isServer) return;
      setLoading(true);

      if (!isAuthenticated) return await fetchLocalLikedColletions();

      const collectionLikesData = await UserService.getUserCollectionLikes(
        client
      );
      const likes = collectionLikesData
        .filter((like) => collections.includes(like?.collection_id))
        .map(({ collection_id }) => getCollection(collection_id));
      setLikedCollections(likes);
      setLoading(false);
    } catch (e) {
      setLikedCollections([]);
      setLoading(false);
    }
  };

  const fetchMyCollections = async () => {
    try {
      if (isServer) return;
      setLoading(true);
      console.log('getMyCollections')
      const myCollectionsData = await UserService.getMyCollections(
        client,
        user?.id,
        app?.id
      );
      setMyCollections(myCollectionsData);
      setLoading(false);
    } catch (e) {
      setMyCollections([]);
      setLoading(false);
    }
  };
  const fetch = () => {
    fetchLikedCollections();
    fetchMyCollections();
  };

  const publicCollections = useMemo(() => {
    const collectionDedupeIndex = likedCollections
      .concat(myCollections)
      .reduce((acc, collection) => ({ ...acc, [collection?.id]: true }), {});
    return collections.filter((c) => !collectionDedupeIndex[c.id]);
  }, [collections, myCollections, likedCollections]);

  const popularCollections = filter(collections ?? [], ({ id }: Collection) => {
    return !!likedCollections.find((c) => {
      return c.id !== id;
    });
  });

  return {
    loading,
    refetch: fetch,
    allCollections: collections,
    likedCollections,
    myCollections,
    popularCollections,
    publicCollections
  };
};

export default useCollections;
