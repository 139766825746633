import { FC, useState } from 'react';
import { Tooltip, IconButton } from '@chakra-ui/react';
import { RiShareForwardFill } from 'react-icons/ri';
import { shareCollection } from 'utils/helpers';
import { DataParams } from 'modules/og';
import { LITERALS, View } from 'utils/constants';

export const NativeSharebutton: FC<DataParams> = ({
  app,
  collection,
  platform,
  view = View.COLLECTION
}) => {
  const [copySuccessFul, setCopySuccessful] = useState<boolean>(false);
  const handleShareClick = async () => {
    const shareType = await shareCollection({
      app,
      collection,
      platform,
      view: View.COLLECTION
    });
    if (shareType === 'clipboard') {
      setCopySuccessful(true);
    }
  };

  return (
    <Tooltip
      hasArrow
      closeOnClick={false}
      label={copySuccessFul ? LITERALS.SHARE_COPY_SUCCESS : LITERALS.SHARE}
      placement="top"
    >
      <IconButton
        _hover={{ bg: 'gray.300' }}
        icon={<RiShareForwardFill />}
        colorScheme="gray"
        color={'gray.500'}
        aria-label={''}
        onClick={handleShareClick}
        onMouseEnter={() => setCopySuccessful(false)}
      />
    </Tooltip>
  );
};

export default NativeSharebutton;
