import { Box, BoxProps, Heading, Text } from '@chakra-ui/react';
import { BiChevronRight, BiX } from 'react-icons/bi';
import { ListItem } from 'components/molecules';
import Image from 'next/image';
import { FC } from 'react';

interface MobileSelectorProps {
  open?: boolean;
  placeholderText?: string;
  onChange: (open: boolean) => void;
  text?: string;
  caption?: string;
  imageUrl?: string;
  hideSelectedTile?: boolean;
  colorScheme?: [string, string, string];
}

export const MobileSelector: FC<BoxProps & MobileSelectorProps> = ({
  open = false,
  placeholderText = 'Please Select',
  colorScheme = ['brand.700', 'white', 'gray.600'],
  text,
  caption,
  imageUrl,
  hideSelectedTile,
  onChange,
  ...props
}) => {
  const handleOpenToggle = () => {
    onChange(!open);
  };

  return (
    <Box display={{ sm: 'block', md: 'none' }} {...props}>
      {!hideSelectedTile ? (
        <ListItem
          text={
            <Heading size="sm" color={colorScheme[1]}>
              {text}
            </Heading>
          }
          caption={
            <Text fontSize="xs" color={colorScheme[1]}>
              {caption}
            </Text>
          }
          image={imageUrl && <Image height={35} width={35} src={imageUrl} alt={`${text}`} />}
          bg={colorScheme[0]}
          actionText={
            open ? (
              <Text fontSize="sm" fontWeight={'500'} color={colorScheme[1]}>
                Close
              </Text>
            ) : (
              <Text fontSize="sm" fontWeight={'500'} color={colorScheme[1]}>
                Select
              </Text>
            )
          }
          actionIcon={
            open ? (
              <BiX color={colorScheme[1]} height={25} width={25} />
            ) : (
              <BiChevronRight color={colorScheme[1]} height={25} width={25} />
            )
          }
          cursor="pointer"
          p={3}
          onClick={handleOpenToggle}
        />
      ) : (
        <></>
      )}
      {open ? (
        <ListItem
          text={
            <Text fontSize="sm" color={colorScheme[2]}>
              {placeholderText}
            </Text>
          }
          px={3}
          pt={3}
          onClick={handleOpenToggle}
        />
      ) : (
        <></>
      )}
    </Box>
  );
};

export default MobileSelector;
