import { GetStaticPaths, GetStaticProps } from 'next';
import { App, Collection } from '@workgood/types';
import { initializeApollo } from 'utils/apollo/client';
import { useAppPrefetch } from 'services';
import { parseUrl, getAppView } from 'utils/route';
import AppService from 'services/AppService';
import { Container } from 'modules/navigation/components';

export const getStaticProps: GetStaticProps = async (context) => {
  const { slug } = context.params ?? {};
  const { appSlug, collectionId } = parseUrl(slug);
  const { prefetch } = useAppPrefetch();
  const apolloClient = initializeApollo();

  var exploreApps: App[], exploreCollections: Collection[];
  const { apps, app, collections, collection } = await prefetch({
    client: apolloClient,
    appSlug,
    collectionId
  });

  return {
    props: {
      bodyOverflowHidden: true,
      apps: exploreApps ?? apps,
      slug: slug ?? {},
      initialApolloState: apolloClient.cache.extract(),
      initialApp: app ?? null,
      initialCollections: exploreCollections ?? collections ?? [],
      initialCollection: collection ?? null
    },
    revalidate: 86400 // 1 day
  };
};

export const getStaticPaths: GetStaticPaths = async () => {
  const apolloClient = initializeApollo();
  const slugs = await AppService.getAllPaths(apolloClient);

  const paths = slugs.map((slug) => ({
    params: { slug }
  }));

  return {
    paths,
    fallback: 'blocking'
  };
};

const Component = (props) => {
  return (
    <>
      <Container {...props}></Container>
    </>
  );
};

export default Component;
