import { FC } from 'react';
import millify from 'millify';
import { Flex, Text, Tooltip } from '@chakra-ui/react';
import { HiBadgeCheck } from 'react-icons/hi';
import get from 'lodash/get';
import { Feature } from '@workgood/types';
import LikeButton from './LikeButton';
import { STATS_DISPLAY_THRESHOLD } from 'utils/constants';

interface FeatureStatProps {
  feature?: Feature;
  size?: 'sm' | 'md';
  showLabel?: true;
  color?: string;
}

export const FeatureStat: FC<FeatureStatProps> = ({
  feature,
  size = 'sm',
  showLabel = false,
  color = 'gray.400'
}) => {
  const likes = ~~get(feature, 'Likes_aggregate.aggregate.count');
  const practices = ~~get(feature, 'User_Features_aggregate.aggregate.count');

  return (
    <Flex align={'center'}>
      {likes > STATS_DISPLAY_THRESHOLD ||
      practices > STATS_DISPLAY_THRESHOLD ? (
        <>
          <LikeButton
            feature={feature}
            size="smaller"
            label="Stars"
            postText={`${millify(likes)} ${showLabel ? ' likes' : ' '}`}
          />
          {/* <Tooltip
            label={`Stars`}
            fontSize="sm"
            bg="blue.800"
            isDisabled={showLabel}
          >
            <Flex alignItems={'center'} mr={4}>
              <Text color={color} mr={1}>
                <RiThumbUpFill size={16} />
              </Text>
              <Text fontSize={'smaller'} color={color}>
                {millify(likes)}
                {showLabel ? ' likes' : ' '}
              </Text>
            </Flex>
          </Tooltip> */}

          <Tooltip
            label={`Users who practiced`}
            fontSize="sm"
            bg="blue.800"
            isDisabled={showLabel}
          >
            <Flex alignItems={'center'} mr={4} ml={2}>
              <Text color={color} mr={0.5}>
                <HiBadgeCheck size={20} />
              </Text>
              <Text fontSize={'smaller'} color={color}>
                {millify(practices)}
                {showLabel ? ' practiced' : ' '}
              </Text>
            </Flex>
          </Tooltip>
        </>
      ) : (
        <></>
      )}
    </Flex>
  );
};

export default FeatureStat;
