import React, { FC, useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  Box,
  Text,
  Heading
} from '@chakra-ui/react';
import { FaCaretDown, FaCaretRight } from 'react-icons/fa';

interface CollapseProps {
  title?: string;
  head?: React.ReactNode;
  content: React.ReactNode;
  defaultExpanded?: boolean;
}

const Collapse: FC<CollapseProps> = ({
  content,
  title,
  head,
  defaultExpanded = true
}) => {
  const [expanded, setExpanded] = useState(defaultExpanded);
  const handleChange = (index) => {
    setExpanded(index !== -1);
  };
  return (
    <Accordion
      onChange={handleChange}
      allowToggle={true}
      defaultIndex={defaultExpanded ? 0 : -1}
    >
      <AccordionItem>
        {head || (
          <h2>
            <AccordionButton color="gray.400">
              <Text ml={4}>{expanded ? <FaCaretDown /> : <FaCaretRight />}</Text>
              <Heading size="xs" ml={2}>
                {title}
              </Heading>
            </AccordionButton>
          </h2>
        )}
        <AccordionPanel>{content}</AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default Collapse;
