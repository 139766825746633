import kebabCase from 'lodash/kebabCase';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import toLower from 'lodash/toLower';
import startCase from 'lodash/startCase';
import get from 'lodash/get';
import { App, Collection } from '@workgood/types';
import {
  GET_APPS,
  GET_APP_COLLECTIONS_BY_SLUG,
  GET_COLLECTION,
  GET_ALL_COLLECTION_IDS,
  GET_LATEST_APPS_AND_COLLECTIONS,
  GET_APP_BY_SLUG,
  SEARCH_COLLECTIONS
} from 'utils/queries';

export class AppService {
  client: ApolloClient<NormalizedCacheObject>;

  constructor(_client: ApolloClient<NormalizedCacheObject>) {
    this.client = _client;
  }

  static async getApps(
    client: ApolloClient<NormalizedCacheObject>
  ): Promise<App[]> {
    try {
      const { data } = await client.query({
        query: GET_APPS
      });
      return data.App as App[];
    } catch {
      return [];
    }
  }

  static async getApp(
    client: ApolloClient<NormalizedCacheObject>,
    appSlug: string
  ): Promise<App> {
    try {
      const { data } = await client.query({
        query: GET_APP_BY_SLUG,
        fetchPolicy: 'cache-first',
        variables: {
          slug: appSlug
        }
      });

      return data.App[0] as App;
    } catch (e) {
      return null;
    }
  }

  static async getCollections(
    client: ApolloClient<NormalizedCacheObject>,
    appSlug: string
  ): Promise<Collection[]> {
    try {
      const { data } = await client.query({
        query: GET_APP_COLLECTIONS_BY_SLUG,
        fetchPolicy: 'cache-first',
        variables: {
          slug: appSlug
        }
      });
      return data.App[0]?.Collections as Collection[] ?? [];
    } catch (e) {
      return [];
    }
  }

  static async searchCollections(
    client: ApolloClient<NormalizedCacheObject>,
    term: string,
    app_id: string
  ): Promise<Collection[]> {
    try {
      const { data } = await client.query({
        query: SEARCH_COLLECTIONS,
        variables: {
          term,
          app_id
        }
      });
      return get(data, 'Collection') as Collection[];
    } catch (e) {
      return [];
    }
  }

  static async getCollection(
    client: ApolloClient<NormalizedCacheObject>,
    collectionId: string,
    forceRefetch = false
  ): Promise<Collection> {
    try {
      const { data } = await client.query({
        query: GET_COLLECTION,
        variables: {
          id: collectionId
        },
        fetchPolicy: forceRefetch ? 'network-only' : 'cache-first'
      });
      return data.Collection_by_pk as Collection;
    } catch (e) {
      return null;
    }
  }

  static async getAllPaths(
    client: ApolloClient<NormalizedCacheObject>
  ): Promise<string[][]> {
    try {
      const { data } = await client.query({
        query: GET_ALL_COLLECTION_IDS
      });
  
      const collectionPaths = (data.App as App[]).reduce(
        (acc, { slug, Collections }) => {
          if (Collections?.length) {
            acc.push([slug]);
            acc.push(
              ...Collections.map(({ name, id }) => [slug, kebabCase(name), id])
            );
          }
          return acc;
        },
        []
      );
  
      return collectionPaths;
    } catch (e) {
      return [];
    }
  }

  static async getExploreData(
    client: ApolloClient<NormalizedCacheObject>,
    app?: string,
    category?: string
  ): Promise<{
    apps: App[];
    collections: Collection[];
    category: string;
  }> {
    const variables = { app: '%', category: '%' };
    // if (category) {
    //   variables.category = startCase(toLower(category));
    // }
    if (app) {
      variables.app = app;
    }
    const { data } = await client.query({
      query: GET_LATEST_APPS_AND_COLLECTIONS,
      variables
    });
    return {
      apps: data?.App as App[],
      category,
      collections: data?.Collection as Collection[]
    };
  }
}

export default AppService;
