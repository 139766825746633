import { createOgDescription, createOgImageUrl, createOgTitle, getOgImageTemplate, OgParams } from 'modules/og';
import { NextSeo } from 'next-seo';
import { getCanonicalUrl } from 'utils/route';

export default ({ title, ogParams = {} as OgParams }) => {
  const images = createOgImageUrl(ogParams);
  const description = createOgDescription(ogParams);
  const url = getCanonicalUrl();

  return (
    <NextSeo
      title={title}
      description={description}
      openGraph={{
        images,
        url,
        title,
        description,
        site_name: 'WorkGood'
      }}
      twitter={{
        handle: '@workgoodio',
        site: 'https://workgood.io'
      }}
    />
  );
};
