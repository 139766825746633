import { useState } from 'react';
import {
  ApolloClient,
  NormalizedCacheObject,
  useApolloClient
} from '@apollo/client';
import { Config } from '@workgood/types';
import ConfigService from '../ConfigService';

let _configs: Config[];
let _configPromise: Promise<Config[]>;

export const useConfig = (): {
  ready: boolean;
  configs: Config[];
  get: (key: string) => unknown;
} => {
  console.log({ _configs, _configPromise });
  const [ready, setReady] = useState<boolean>(!!_configs);
  const [configs, setConfigs] = useState<Config[]>(_configs);
  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>;

  if (!configs && !_configPromise) {
    const _service = new ConfigService(client);
    _configPromise = _service.getConfig(client);
    _configPromise.then((data) => {
      _configs = data;
      setConfigs(data);
    });
    _configPromise.finally(() => setReady(true));
  }

  const get = (key: string): any => {
    if (!configs) return;
    const config = configs.find((c) => c.key === key);
    return config?.value ? JSON.parse(config.value) : null;
  };

  return { configs, get, ready };
};

export default useConfig;
