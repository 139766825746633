import { App } from '@workgood/types';
import { Badge, Box, Button, Flex, Heading, Spinner } from '@chakra-ui/react';
import { useState } from 'react';
import CardListItem from 'components/molecules/CardListItem';
import { buildUrl } from 'utils/route';
import { getImageUrl } from 'utils/image';
import { BsChevronRight } from 'react-icons/bs';
import AppLogo from 'components/atoms/AppLogo';
import { LITERALS } from 'utils/constants';
import { getPlatform } from 'utils/device';

export function AppCategoryList({
  title,
  apps,
  initialLimit = 3
}: {
  apps: App[];
  title: string;
  initialLimit?: number;
}) {
  const [limit, setLimit] = useState(initialLimit);
  const [loadingApp, setLoadingApp] = useState<string>('');
  const appsToShow = limit ? apps.slice(0, limit) : apps;
  const showSeeAllButton = apps?.length > limit && limit !== 0;
  const platform = getPlatform();

  const handleLoadMoreClick = () => {
    setLimit(0);
  };

  const handleAppClick = (app) => {
    setLoadingApp(app.slug);
    setTimeout(() => setLoadingApp(app.slug),  500);
  }
  
  return (
    <Flex mb={8} direction='column' w='100%'>
      <Flex
        w="100%"
        justifyContent={'space-between'}
        align="center"
        mb={2}
      >
        <Heading
          fontWeight={500}
          fontSize={18}
          size="sm"
          alignSelf={'flex-start'}
        >
          {title}
        </Heading>
        {showSeeAllButton && (
          <Button variant={'link'} onClick={handleLoadMoreClick}>
            See All
          </Button>
        )}
      </Flex>
      <Flex flexWrap={'wrap'} justifyContent='space-between'>
      {appsToShow.map((app) => {
        const urlParams = { platform, appSlug: app.slug } as any; 
        // if (app?.default_collection_id) {
        //   urlParams.collectionId = app?.default_collection_id;
        //   urlParams.collectionName = LITERALS.TOP_SHORTCUTS;
        // }
        
        const url = buildUrl(urlParams);
        return (
          <Flex w={{base: '100%'}}  >
            <CardListItem
            size="sm"
            url={url}
            title={app.name}
            badge={
              !app.is_free && (
                <Badge
                  fontSize="0.7rem"
                  ml={1}
                  height="17px"
                  mt={1}
                  colorScheme={'purple'}
                >
                  {LITERALS.PREMIUM_USER_TAG}
                </Badge>
              )
            }
            leftImage={
              <AppLogo
                params={['c_pad']}
                dimensions={[24, 24]}
                src={getImageUrl(`logos/${app.slug}.png`)}
                alt={`${app.name}`}
              />
            }
            onClick={() => handleAppClick(app)}
            rightContent={
              loadingApp === app.slug ? (
                <Spinner color={'gray'} size={'sm'} />
              ) : (
                <BsChevronRight />
              )
            }
          />
          </Flex>
        );
      })}
      </Flex>
    </Flex>
  );
}

export default AppCategoryList;
